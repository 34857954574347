import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import SearchBar from "../../components/controls/SearchBar";
import Filter from "../../components/Filter";
import RankCard from "../../components/Rank/RankCard";
import BaseContext from "../../context/BaseContext";
import { getPlatforms, platform, platform_all } from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";
import { filter } from "../BlogPage";

export const FilterTab_Rank: filter[] = [
    {
        button_text: "綜合評分",
        Type: 999,
    },
    {
        button_text: "報酬率",
        Type: 1,
    },
    {
        button_text: "真實性",
        Type: 2,
    },
    {
        button_text: "風險控制",
        Type: 3,
    },
    {
        button_text: "抵押擔保",
        Type: 4,
    },
    {
        button_text: "違約催收",
        Type: 5,
    },
    {
        button_text: "回收速度",
        Type: 6,
    },
    {
        button_text: "案件數量",
        Type: 7,
    },
    {
        button_text: "活躍度",
        Type: 8,
    },
];

export default function RankPage() {
    const location = useLocation();
    const [sFilterType, setFilterType] = useState<number>(999);
    const [vPlatform, setPlatform] = useState<platform[]>([]);
    const [sPlatUpdate, setPlatUpdate] = useState<string>("");

    const [bShowCard, setShowCard] = useState<boolean>(true);
    const [sListStyle, setListStyle] = useState<{ margin: string }>({ margin: "-138px auto 0 auto" });

    const { ChangeURL, setAlert } = useContext(BaseContext);

    const getAllPlatform = () => {
        setFilterType(999);
        getPlatforms().then((res: platform_all) => {
            if (res.success) {
                sortList(res.platforms);
                setPlatUpdate(res.updated_at);
            } else {
                VerifyAlert({
                    title: "拿取平台資料失敗！",
                    content: ``,
                    btnText: "返回",
                    MsgType: 2,
                    btnOnclick: () => {
                        ChangeURL("/");
                        setAlert("Msg", false);
                    },
                    setAlert: setAlert,
                });
            }
        });
    };

    const sortPlat = (tempPlatform: platform[]) => {
        if (sFilterType === 999) {
            tempPlatform.sort((a: platform, b: platform) => {
                return b.score.total - a.score.total;
            });
        } else {
            tempPlatform.sort((a: platform, b: platform) => {
                return b.score["score" + sFilterType.toString()] - a.score["score" + sFilterType.toString()];
            });
        }
        return tempPlatform;
    };

    const sortList = (vPlat: platform[]) => {
        if (vPlat.length !== 0) {
            const tempPlatformWithRank: platform[] = sortPlat(
                JSON.parse(JSON.stringify(vPlat.filter((item) => item.rank !== 0)))
            );
            const tempPlatformWithoutRank: platform[] = sortPlat(
                JSON.parse(JSON.stringify(vPlat.filter((item) => item.rank === 0)))
            );
            const AllSortPlat: platform[] = tempPlatformWithRank.concat(tempPlatformWithoutRank);

            checkShowCard(AllSortPlat.slice(0, 3));
            setPlatform(AllSortPlat);
        }
    };
    //檢查是否需要顯示上方卡片，如若前三名有一個rank 0 則不排出來
    const checkShowCard = (vPlat: platform[]) => {
        vPlat.forEach((plat: platform) => {
            if (plat.rank === 0) {
                setShowCard(false);
                return;
            }
        });
    };

    useEffect(() => {
        sortList(vPlatform);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sFilterType]);

    useEffect(() => {
        if (!bShowCard) {
            //如果沒有顯示排名卡則將列表往上移
            const width = window.screen.width;
            if (width > 960) {
                setListStyle({
                    margin: "-113px auto 0 auto",
                });
                return;
            }
            setListStyle({
                margin: "-328px auto 0 auto",
            });
        }
    }, [bShowCard]);

    useEffect(() => {
        const width = window.screen.width;
        if (width > 960) {
            setListStyle({
                margin: "-113px auto 0 auto",
            });
        }
        getAllPlatform();
        document.title = "P2P平台排名｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SearchBar />
            <div id="RankPage">
                <div className="top-block">
                    <div className="top-info">
                        <Breadcrumb needHome={true} locationPath={location.pathname} />
                        <div className="top-rank-info">
                            <div className="top-rank-info-filter">
                                <div className="top-info-title">平台排名總覽</div>
                                <Filter
                                    setFilterSelect={setFilterType}
                                    FilterSelect={sFilterType}
                                    defaultText={"排序"}
                                    FilterTab={FilterTab_Rank}
                                />
                                {sPlatUpdate !== "" && (
                                    <div className="update-date">
                                        最近更新時間：{sPlatUpdate.split(" ")[0].replaceAll("-", "/")} &nbsp;
                                        {sPlatUpdate.split(" ")[1].split(":")[0]}:
                                        {sPlatUpdate.split(" ")[1].split(":")[1]}
                                    </div>
                                )}
                            </div>
                            <div className="rank-card">
                                {bShowCard && <RankCard vPlatform={vPlatform.slice(0, 3)} filterType={sFilterType} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rank-list" style={sListStyle}>
                    <div className="rank-thead">
                        <div>排名</div>
                        <div>平台</div>
                        {FilterTab_Rank.slice(1, 9).map((item) => {
                            return (
                                <div key={item.button_text} data-label={"desktop-show"}>
                                    {item.button_text}
                                </div>
                            );
                        })}
                        <div data-label={"desktop-show"}>綜合評分</div>
                        <div data-label={"mobile-show"}>
                            {FilterTab_Rank[FilterTab_Rank.findIndex((item) => item.Type === sFilterType)].button_text}
                        </div>
                    </div>
                    <div className="rank-tbody">
                        <div className="plat-block">
                            {vPlatform.length === 0 ? (
                                <div className="plat-item-none">無其他平台</div>
                            ) : (
                                vPlatform.map((plat, index) => {
                                    return (
                                        <div
                                            className="plat-item"
                                            key={index}
                                            onClick={() => ChangeURL("/rankpage/" + plat.platform_code)}
                                        >
                                            <div>{plat.rank !== 0 ? index + 1 : "未排名"}</div>
                                            <div className="platform-thead-name">
                                                <img src={plat.image} alt="" />
                                                {plat.platform_name}
                                            </div>
                                            {FilterTab_Rank.slice(1, 9).map((item) => {
                                                return (
                                                    <div
                                                        key={item.button_text}
                                                        data-label={"desktop-show"}
                                                        className={`plat-item-score ${
                                                            item.Type === sFilterType
                                                                ? "selected-score"
                                                                : "not-selected-score"
                                                        }`}
                                                    >
                                                        {plat.score["score" + item.Type]}
                                                    </div>
                                                );
                                            })}
                                            <div
                                                data-label={"desktop-show"}
                                                className={`plat-item-score ${
                                                    FilterTab_Rank[0].Type === sFilterType
                                                        ? "selected-score"
                                                        : "not-selected-score"
                                                }`}
                                            >
                                                {plat.score.total}
                                            </div>
                                            <div data-label={"mobile-show"} className="plat-item-score">
                                                {sFilterType === 999
                                                    ? plat.score.total
                                                    : plat.score["score" + sFilterType]}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
                <div className="hint">＊上述列表顯示「未排名」為評分數不足，故未列入排名。</div>
                <div className="about-rank-block">
                    <div className="about-rank-title">關於平台排名</div>
                    <div className="about-rank-info">
                        平台的排名為用戶對於該平台的使用評價作為主要依據，我們希望從用戶身上獲得最真實的使用評價，以維持
                        P2P 社群守望相助的氛圍。
                    </div>
                    <div
                        className="about-rank-link"
                        onClick={() => (window.location.href = `${window.location.origin}/blog/scoring`)}
                    >
                        了解評分機制
                        <img src={process.env.PUBLIC_URL + "/img/icon-chevron-right.svg"} alt="" />
                    </div>
                </div>
                <div className="login-platform">
                    <div className="login-platform-block">
                        <div className="login-platform-text">
                            <div className="login-platform-title">登錄平台取得認證</div>
                            <img
                                className="login-platform-img"
                                src={process.env.PUBLIC_URL + "/img/pic-cooperation.png"}
                                alt=""
                            />
                            <div className="login-platform-info">
                                希望提高您網站的能見度？快速接觸投資者？或是發現新的合作夥伴和管道？攜手
                                P2PRank，讓您公開的流量排名能真正反映您在現實世界中的成功。讓您的網站取得認證，提升您的線上聲譽。
                            </div>
                            <div className="login-platform-btn">
                                <Button isShowIcon={false} onclickFn={() => ChangeURL("/contact")} btnName="聯絡我們" />
                                {/* <div className="login-platform-specification">
                                    了解登錄規範
                                    <img src={process.env.PUBLIC_URL + '/img/icon-chevron-right.svg'} alt="" />
                                </div> */}
                            </div>
                        </div>
                        <img
                            className="login-platform-img-desktop"
                            src={process.env.PUBLIC_URL + "/img/pic-cooperation.png"}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
