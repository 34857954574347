function Loading() {
    return (
        <div
            id="Loading"
            className="loading-back"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="container">
                <svg viewBox="0 0 100 100">
                    <defs>
                        <filter id="shadow">
                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" flood-color="#00278d" />
                        </filter>
                    </defs>
                    <circle className="spinner" cx="50" cy="50" r="45" />
                </svg>
            </div>
        </div>
    );
}

export default Loading;
