import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* 換頁資訊按鈕 */
interface props {
    backgroundDark?: boolean;
    isShowIcon: boolean;
    btnName: string;
    onclickFn?: (() => void) | ((e: any) => void);
    disable?: boolean;
}

const Button = ({ backgroundDark = false, btnName, isShowIcon, onclickFn, disable = false }: props) => {
    return (
        <>
            <div className="btn">
                <button
                    disabled={disable}
                    className={`change-btn ${backgroundDark ? "with-blue-bg-dark" : "with-blue-bg"} ${
                        disable && "disable-btn"
                    }`}
                    onClick={onclickFn}
                >
                    {btnName}
                    {isShowIcon && <FontAwesomeIcon className="icon" icon={faArrowRight as IconProp} />}
                </button>
            </div>
        </>
    );
};

export default Button;
