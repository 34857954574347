import React from "react";
import { enumModifyTab } from "../../pages/User/ModifyPage";

interface props {
    selected: string;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const ModifyTab = ({ selected, setSelectedTab }: props) => {
    const handleSelect = (tabName: string) => {
        setSelectedTab(tabName);
    };

    return (
        <div className="Tabs">
            {Object.keys(enumModifyTab).map((item: string) => (
                <div
                    key={enumModifyTab[item]}
                    className={`${selected === enumModifyTab[item] ? "active" : ""}`}
                    onClick={() => handleSelect(enumModifyTab[item])}
                >
                    {enumModifyTab[item]}
                </div>
            ))}
        </div>
    );
};

export default ModifyTab;
