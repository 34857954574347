import { useState } from "react";
import ResetPassword from "../../pages/User/ResetPassword";
import { reportPlatform } from "../ScorePage/ReportPlatform";

export interface accountCheck {
    account: string;
    password: string;
    [x: string]: string;
}

export interface accountRegister {
    name: string;
    email: string;
    password: string;
    oauth_from: string;
    oauth_id: string;
    [x: string]: string;
}

export interface modifyPassword {
    old_password: string;
    new_password: string;
    [x: string]: string;
}

export interface bankInfo {
    bankCode: string; //銀行代碼
    bankBranch: string; //銀行分行名稱
    bankAccount: string; //銀行帳號
    AccountName: string; //銀行戶名
    // birthday: string //生日
    [x: string]: string;
}

export const useForm = (
    initialFValues: accountCheck | accountRegister | modifyPassword | bankInfo | reportPlatform | ResetPassword,
    values: accountCheck | accountRegister | modifyPassword | bankInfo | reportPlatform | ResetPassword,
    setValues: any
) => {
    const [errors, setErrors] = useState<
        accountCheck | accountRegister | modifyPassword | bankInfo | reportPlatform | ResetPassword
    >(initialFValues);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const resetForm = () => {
        setValues(initialFValues);
        setErrors(initialFValues);
    };

    return {
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    };
};
