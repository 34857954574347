import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import Input from '../../components/controls/Input'
import { useForm } from '../../components/controls/useForm'
import BaseContext from '../../context/BaseContext'
import { putPassword } from '../../util/api'
import { VerifyAlert } from '../../util/dataProcess'
interface propState {
    phone: string
    code: string
}

interface ResetPassword {
    newpassword: string
    doublecheckpassword: string
    [x: string]: string
}

const initialValues: ResetPassword = {
    newpassword: '',
    doublecheckpassword: '',
}

const ResetPassword = () => {
    const location = useLocation()

    const { errorProcess, ChangeURL, setAlert } = useContext(BaseContext)

    const [vResetPassword, setResetPassword] = useState<ResetPassword>(initialValues)

    const { errors, setErrors, handleInputChange } = useForm(initialValues, vResetPassword, setResetPassword)

    let { phone, code } = location.state as propState

    const validate = (fieldValues = vResetPassword) => {
        let temp: ResetPassword = { ...(errors as ResetPassword) }
        if ('newpassword' in fieldValues)
            temp.newpassword =
                fieldValues.newpassword === ''
                    ? '此欄位必填'
                    : /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/.test(fieldValues.newpassword)
                    ? ''
                    : '請混合使用 8 個字元以上的英文字母、數字和符號'
        if ('doublecheckpassword' in fieldValues)
            temp.doublecheckpassword =
                fieldValues.doublecheckpassword === ''
                    ? '此欄位必填'
                    : fieldValues.doublecheckpassword !== fieldValues.newpassword
                    ? '與新密碼不同，請重新輸入'
                    : ''
        setErrors({
            ...temp,
        })
        if (fieldValues === vResetPassword) return Object.values(temp).every((x) => x === '')
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            putPassword({ phone: phone, code: code, password: vResetPassword.newpassword })
                .then((res) => {
                    if (res.success) {
                        VerifyAlert({
                            title: '密碼重設完成',
                            content: '',
                            btnText: '立即登入',
                            MsgType: 1,
                            btnOnclick: () => {
                                ChangeURL('/login')
                                setAlert('Msg', false)
                            },
                            setAlert: setAlert,
                        })
                    } else {
                        VerifyAlert({
                            title: '更新失敗',
                            content: `${res.msg}`,
                            btnText: '返回',
                            MsgType: 2,
                            btnOnclick: () => {
                                ChangeURL('/login')
                                setAlert('Msg', false)
                            },
                            setAlert: setAlert,
                        })
                    }
                })
                .catch((error: any) => {
                    console.error(error)
                    let error_status: number
                    if (error.response !== undefined) {
                        error_status = error.response.status
                    } else {
                        error_status = 0
                    }
                    errorProcess(error_status, error)
                })
        }
    }

    useEffect(() => {
        document.title = '重設密碼｜P2PRank'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="ResetPassword">
            <Breadcrumb locationPath={location.pathname} />
            <div className="resetpassword-block">
                <div className="title">重設密碼</div>
                <div className="input-block">
                    <Input
                        name="newpassword"
                        label="新密碼"
                        value={vResetPassword.newpassword}
                        onChange={(e) => handleInputChange(e)}
                        error={errors.newpassword}
                        type={'password'}
                        hasPlaceholder={false}
                    />
                    <p className="hint">請混合使用 8 個字元以上的英文字母、數字和符號</p>
                    <Input
                        name="doublecheckpassword"
                        label="確認密碼"
                        value={vResetPassword.doublecheckpassword}
                        onChange={(e) => handleInputChange(e)}
                        error={errors.doublecheckpassword}
                        type={'password'}
                        hasPlaceholder={false}
                    />
                </div>
                <div className="reset-password-btn">
                    <Button isShowIcon={false} btnName="完成" onclickFn={(e) => handleSubmit(e)} />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
