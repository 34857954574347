import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from './Button'
import BaseContext from '../context/BaseContext'
import SearchBar from './controls/SearchBar'
import { env } from 'process'

const Navbar = () => {
    const [bClick, setClick] = useState(false)
    const [bGray, setGray] = useState(false)
    const [size, setSize] = useState(0)

    const { pathname } = useLocation()

    const { ChangeURL, beLogin } = useContext(BaseContext)

    useEffect(() => {
        setClick(false)
        setTimeout(() => {
            setGray(false)
        }, 500)
    }, [pathname])

    const handleClick = () => {
        setClick(!bClick)
        if (bGray) {
            setTimeout(() => {
                setGray(false)
            }, 500)
        } else {
            setGray(true)
        }
    }
    const closeMobileMenu = () => {
        setClick(false)
        setTimeout(() => {
            setGray(false)
        }, 500)
    }

    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth)
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    useEffect(() => {
        const localBeLogin = window.localStorage.getItem('beLogin')
        if (localBeLogin !== undefined) {
            if (localBeLogin === 'true') {
                beLogin.current = true
            } else {
                beLogin.current = false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (size < 960 && bClick) {
            setClick(false)
            setTimeout(() => {
                setGray(false)
            }, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size])

    let navigate = useNavigate()

    function changeLocation(placeToGo: string) {
        navigate(placeToGo, { replace: true })
        window.location.reload()
    }

    const openSearch = () => {
        const SearchNav = document.getElementById('Search-nav')
        if (SearchNav) {
            if (SearchNav.style.display === 'none' || SearchNav.style.display === '') {
                SearchNav.style.display = 'block'
            } else {
                SearchNav.style.display = 'none'
            }
        }
    }

    useEffect(() => {
        const elements = document.getElementsByClassName('navbar') as HTMLCollectionOf<HTMLElement>
        if (bClick) {
            if (elements) elements[0].style.zIndex = '1000'
        } else {
            setTimeout(() => {
                if (elements) elements[0].style.zIndex = '999'
            }, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bClick])

    return (
        <div id="Navbar">
            <nav className={`navbar sticky navbar-shadow`}>
                <Link to="/" className="navbar-logo">
                    <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" />
                </Link>
                {bGray && <div className="back-grey"></div>}
                <ul className={bClick ? 'nav-menu active' : 'nav-menu'}>
                    {/* bClick true 等於 nav-menu 是展開的*/}
                    <div>
                        <img
                            className="close-icon"
                            src={process.env.PUBLIC_URL + '/img/icon-menu-close.svg'}
                            alt=""
                            onClick={handleClick}
                        />
                    </div>
                    <div className={`${bClick ? 'first-nav-block' : ''}`}>
                        <div className="search-block">
                            <SearchBar />
                        </div>

                        <li className="nav-item">
                            <Link to="/rankpage" className="nav-links" onClick={closeMobileMenu}>
                                平台排名
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/blog"
                                className="nav-links"
                                onClick={() => {
                                    closeMobileMenu()
                                    changeLocation('/blog')
                                }}
                            >
                                投資消息
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                                關於我們
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
                                聯絡我們
                            </Link>
                        </li>
                        <li className="nav-item desktop" onClick={openSearch}>
                            <div className="nav-links">
                                <img
                                    className="search-icon"
                                    src={process.env.PUBLIC_URL + '/img/icon-search.svg'}
                                    alt=""
                                />
                                探索
                            </div>
                        </li>
                        <div className={`nav-login-block`}>
                            {beLogin.current ? (
                                <div className="user-img desktop" onClick={() => ChangeURL('/user')}>
                                    <img src={process.env.PUBLIC_URL + '/img/user-face.png'} alt="" />
                                </div>
                            ) : (
                                <>
                                    <li className="nav-item desktop">
                                        <Link to="/login" className="desktop-login" onClick={closeMobileMenu}>
                                            登入
                                        </Link>
                                    </li>
                                    <li className="nav-item desktop">
                                        <div
                                            className="desktop-register"
                                            onClick={() => {
                                                closeMobileMenu()
                                                navigate('/login/register', {
                                                    state: {
                                                        name: '',
                                                        email: '',
                                                        id: '',
                                                        from: '',
                                                    },
                                                })
                                            }}
                                        >
                                            註冊
                                        </div>
                                    </li>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="second-nav-block">
                        {beLogin.current ? (
                            <Button
                                backgroundDark={true}
                                isShowIcon={false}
                                onclickFn={() => ChangeURL('/user')}
                                btnName="管理個人資料"
                            />
                        ) : (
                            <Button
                                backgroundDark={true}
                                isShowIcon={false}
                                onclickFn={() => ChangeURL('/login')}
                                btnName=" 登入／註冊"
                            />
                        )}
                    </div>
                </ul>
                <div className="nav-left">
                    {!beLogin.current && (
                        <div className="mobile-login">
                            <Link to="/login" className="nav-links" onClick={closeMobileMenu}>
                                登入
                            </Link>
                        </div>
                    )}
                    <div className="menu-icon" onClick={handleClick}>
                        <img src={process.env.PUBLIC_URL + '/img/icon-menu.svg'} alt="" />
                    </div>
                </div>
            </nav>
            <SearchBar divID={'Search-nav'} />
        </div>
    )
}

export default Navbar
