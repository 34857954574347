import React from "react";
import { useNavigate } from "react-router-dom";
import { FilterTab, iArticle } from "../../pages/BlogPage";

interface props {
    Article: iArticle;
}

const Article = ({ Article }: props) => {
    const navigate = useNavigate();
    return (
        <div
            id="Article"
            className="article"
            onClick={() => {
                navigate(`/blog/${Article.article_code}`);
            }}
        >
            <img src={Article.image} alt="" />
            <div className="article-class-blur">
                <div className="article-class">
                    {FilterTab[FilterTab.findIndex((item) => item.Type === Article.article_type)].button_text}
                </div>
            </div>
            <div className="article-item">
                <div className="article-info">
                    <div className="article-date">{Article.created_at.split(" ")[0].replaceAll("-", ".")}</div>
                </div>
                <div className="article-title">{Article.title}</div>
                <div className="article-tag">
                    {Article.tags !== null &&
                        Article.tags.map((item: { value: string }) => {
                            return <span key={item.value}>#{item.value}</span>;
                        })}
                </div>
            </div>
        </div>
    );
};

export default Article;
