import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Button from "../components/Button";

const ContactUs = () => {
    const location = useLocation();

    useEffect(() => {
        document.title = "聯絡我們｜P2PRank ";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div id="ContactUs">
            <div className="contact-page">
                <Breadcrumb needHome={true} locationPath={location.pathname} />

                <div className="contenct-block">
                    <div className="contact-item">
                        <div className="contact-title">聯絡我們以取得協助</div>
                        <div className="contact-item-img-mobile">
                            <img src={process.env.PUBLIC_URL + "/img/pic-contact.png"} alt="" />
                        </div>
                        <div className="contact-item-content">
                            如您有任何問題，歡迎使用 LINE 或 E-mail 與我們聯繫，我們將盡快回覆，解決您的問題。
                        </div>
                        <div className="contact-btn">
                            <Button
                                isShowIcon={false}
                                btnName="LINE 即時客服"
                                onclickFn={() => (window.location.href = "https://lin.ee/cT56bxu")}
                            />
                            <Button
                                isShowIcon={false}
                                btnName="聯絡信箱"
                                onclickFn={() => (window.location.href = "mailto:support@p2prank.tw")}
                            />
                        </div>
                    </div>
                    <div className="contact-item-img-desktop">
                        <img src={process.env.PUBLIC_URL + "/img/pic-contact.png"} alt="" />
                    </div>
                </div>
                {/* <div className="contact-specification">
                了解登錄規範
                <img src={process.env.PUBLIC_URL + '/img/icon-chevron-right.svg'} alt="" />
            </div> */}
            </div>
        </div>
    );
};

export default ContactUs;
