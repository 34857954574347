import { env } from "process";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import Button from "../components/Button";
import BaseContext from "../context/BaseContext";

const AboutUs = () => {
    const location = useLocation();

    const { ChangeURL } = useContext(BaseContext);

    useEffect(() => {
        document.title = "關於我們｜P2PRank";
    }, []);
    return (
        <div id="AboutUs">
            <div className="about-page">
                <Breadcrumb needHome={true} locationPath={location.pathname} />
                <div className="about-page-title">關於我們</div>
                <div className="about-page-block">
                    <div className="about-page-target">
                        <div className="about-page-target-subtitle">我們的目標</div>
                        <img src={process.env.PUBLIC_URL + "/img/pic-about.png"} alt="" />
                    </div>
                    <div className="about-page-info">
                        <div className="about-page-subitle">台灣第一家P2P平台資訊站</div>
                        <div className="about-page-info-text">
                            台灣的P2P平台發展較晚，資訊較少，各家平台品質不一，為了讓更多使用者能夠更了解這個產業，以及更方便的比較每家平台的優劣，我們希望透過P2PRank帶起投資互助風氣，藉由分享投資經驗，以監督平台運作，使台灣P2P產業成長茁壯的同時更可以保護每位使用者的安全。
                            <br />
                            <br />
                            我們是由一群關注P2P平台的好朋友們一同組成，在工作之餘，花自己的時間跟資源來管理這個網站，若有資訊更新太慢或有誤請再通知我們。
                        </div>
                        <div className="about-contact-btn">
                            <Button isShowIcon={false} btnName="聯絡我們" onclickFn={() => ChangeURL("/contact")} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
