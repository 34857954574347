import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BlogArticle from "../components/Blog/BlogArticle";
import BlogArticleClass from "../components/Blog/BlogArticleClass";
import Filter from "../components/Filter";
import Breadcrumb from "../components/Breadcrumb";
import SearchBar from "../components/controls/SearchBar";
import BaseContext from "../context/BaseContext";
export interface iArticle {
    article_code: string;
    title: string;
    image: string;
    article_type: number;
    tags: { value: string }[];
    created_at: string;
    view_count: number;
    og_title: string;
    og_description: string;
    og_image: string;
}

export interface filter {
    button_text: string;
    Type: number;
}

//分類： 全部 , Fintech 投資趨勢 = 1, 活動消息 = 2, 媒體報導 = 3, P2PRank 使用指南 = 4
export const FilterTab: filter[] = [
    {
        button_text: "全部",
        Type: 999,
    },
    {
        button_text: "Fintech 投資趨勢",
        Type: 1,
    },
    {
        button_text: "媒體報導",
        Type: 3,
    },
    {
        button_text: "P2PRank 使用指南",
        Type: 4,
    },
    {
        button_text: "活動消息",
        Type: 2,
    },
];

const BlogPage = () => {
    const location = useLocation();
    const [sClassName, setClassName] = useState<number>(-1);
    const [sBlogFirstPage, setBlogFirstPage] = useState<boolean>(true);

    const { vArticleData } = useContext(BaseContext);

    const showClassData = () => {
        if (sClassName === FilterTab[0].Type) {
            return vArticleData;
        } else {
            return vArticleData.filter((item) => item.article_type === sClassName);
        }
    };

    useEffect(() => {
        if (sClassName !== -1) {
            setBlogFirstPage(false);
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sClassName]);

    useEffect(() => {
        setClassName(-1);
        setBlogFirstPage(true);
        window.scrollTo(0, 0);
        document.title = "投資消息｜P2PRank";
    }, []);

    return (
        <>
            <SearchBar />
            <div id="Blog">
                <Breadcrumb needHome={true} locationPath={location.pathname} />
                <div className="title">掌握 P2P 最新脈動</div>
                <Filter
                    setFilterSelect={setClassName}
                    FilterSelect={sClassName}
                    defaultText={"文章分類"}
                    FilterTab={FilterTab}
                />
                {sBlogFirstPage ? (
                    <BlogArticleClass vArticleData={vArticleData} setClassName={setClassName} />
                ) : (
                    <BlogArticle key={sClassName} vArticleData={showClassData()} sClassName={sClassName} />
                )}
            </div>
        </>
    );
};

export default BlogPage;
