import React, { useContext } from "react";
import { createPortal } from "react-dom";
import BaseContext from "../context/BaseContext";

function Modal() {
    // modal element
    const elModal = document.getElementById("modal");
    // context
    const { bCloseIcon = true, bModal, sModal, sModalTitle, setbModal, bClickBg } = useContext(BaseContext);

    const clickBGtoClose = () => {
        if (bClickBg) setbModal(false);
    };

    const sEl = (
        <div
            className="gray-back"
            onClick={(e) => {
                e.stopPropagation();
                clickBGtoClose();
            }}
        >
            <div className="modal-content">
                {(sModalTitle !== "" || bCloseIcon) && (
                    <div className="title-block">
                        <div className="title">{sModalTitle}</div>
                        {bCloseIcon && (
                            <img
                                src={process.env.PUBLIC_URL + "/img/icon-close.svg"}
                                alt=""
                                onClick={() => setbModal(false)}
                            />
                        )}
                    </div>
                )}

                <div className="content ">{sModal}</div>
            </div>
        </div>
    );

    return <>{bModal && (!elModal ? null : createPortal(sEl, elModal))}</>;
}

export default Modal;
