import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import Input from "../../components/controls/Input";
import BaseContext from "../../context/BaseContext";
import { InviteCodeAPI } from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";

export default function InputInvite() {
    const location = useLocation();
    // context
    const { ChangeURL, beLogin, sIdToken, setAlert, errorProcess } = useContext(BaseContext);

    const [sInviteCode, setInviteCode] = useState<string>("");

    const handleInputChange = (e: any) => {
        setInviteCode(e.target.value);
    };

    const InviteCode = () => {
        InviteCodeAPI(sIdToken.current, { share_code: sInviteCode })
            .then((res: any) => {
                if (res.success) {
                    VerifyAlert({
                        title: "輸入成功！",
                        content: "完成註冊後，我們將派發貢獻值獎勵！",
                        btnText: "繼續",
                        MsgType: 1,
                        btnOnclick: () => {
                            beLogin.current
                                ? ChangeURL("/login/invite/phone")
                                : ChangeURL("/login/register/invite/phone");
                            setAlert("Msg", false);
                        },
                        setAlert: setAlert,
                    });
                } else {
                    if (res.invite_code_not_exist) {
                        VerifyAlert({
                            title: "輸入失敗",
                            content: "輸入的邀請碼無效，請重新輸入。",
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    } else {
                        VerifyAlert({
                            title: "輸入失敗",
                            content: "輸入的邀請碼失敗，請重新輸入。",
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    }
                }
            })
            .catch((error: any) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };

    useEffect(() => {
        document.title = "帳號驗證｜P2PRank";
    }, []);

    return (
        <div id="Invite">
            <Breadcrumb locationPath={location.pathname} />
            <div className="invite-block">
                <div className="title">輸入邀請碼</div>
                <div className="input-invite-block">
                    <span>輸入邀請碼，雙方即可獲得貢獻值獎勵！</span>
                    <div>
                        <Input
                            name="inviteCode"
                            label="邀請碼"
                            value={sInviteCode}
                            onChange={(e) => handleInputChange(e)}
                            error={""}
                            type={"text"}
                            hasPlaceholder={true}
                        />
                    </div>
                </div>
                <div className="submit">
                    <Button isShowIcon={false} btnName="送出" onclickFn={InviteCode} />
                    <span
                        onClick={() => {
                            beLogin.current
                                ? ChangeURL("/login/invite/phone")
                                : ChangeURL("/login/register/invite/phone");
                        }}
                    >
                        略過
                    </span>
                </div>
            </div>
        </div>
    );
}
