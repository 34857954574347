import React, { useContext } from 'react'
import BaseContext from '../../context/BaseContext'
import { platform } from '../../util/api'

interface props {
    vPlatform: platform[]
    filterType: number
}

const RankCard = ({ vPlatform, filterType }: props) => {
    const { ChangeURL } = useContext(BaseContext)

    const order: { order_index: number; order_className: string }[] = [
        {
            order_index: 2,
            order_className: 'rank-card-second',
        },
        {
            order_index: 1,
            order_className: 'rank-card-first',
        },
        {
            order_index: 3,
            order_className: 'rank-card-second',
        },
    ]

    const gotoPlatForm = (platform_code: string) => {
        ChangeURL('/rankpage/' + platform_code)
    }

    return (
        <div id="RankCard">
            {vPlatform.length > 0 &&
                order.map((item: { order_index: number; order_className: string }) => {
                    return (
                        <div
                            key={item.order_index}
                            className={item.order_className}
                            onClick={() => gotoPlatForm(vPlatform[item.order_index - 1].platform_code)}
                        >
                            <div className="rank-number-blur">
                                <p className="rank-number">{item.order_index}</p>
                            </div>
                            <img src={vPlatform[item.order_index - 1].image} alt="" />
                            <div className="platform-name">{vPlatform[item.order_index - 1].platform_name}</div>
                            <div className="score">
                                <img src={process.env.PUBLIC_URL + '/img/icon-star-black.svg'} alt="" />
                                <span className="first-span">
                                    {filterType === 999
                                        ? vPlatform[item.order_index - 1].score.total
                                        : vPlatform[item.order_index - 1].score['score' + filterType]}
                                </span>
                                <span className="second-span">/5.0</span>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default RankCard
