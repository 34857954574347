import { useEffect } from "react";
import { iArticle } from "../../pages/BlogPage";
import Article from "./Article";

interface props {
    vArticleData: iArticle[];
    ArticleScrollIndex: number;
}

const ArticleScroll = ({ vArticleData, ArticleScrollIndex }: props) => {
    const SettingDrag = () => {
        const slider = document.getElementById(`ArticleScroll${ArticleScrollIndex}`);

        let startX: number = 0;
        let scrollLeft: number = 0;

        if (slider !== null) {
            const preventClick = (e: any) => {
                e.preventDefault();
                e.stopImmediatePropagation();
            };
            let isDown = false;
            var isDragged = false;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });

            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mouseup", (e) => {
                isDown = false;
                const elements = document
                    .getElementById(`ArticleScroll${ArticleScrollIndex}`)!
                    .getElementsByClassName("article");
                if (elements.length > 0) {
                    if (isDragged) {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].addEventListener("click", preventClick);
                        }
                    } else {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].removeEventListener("click", preventClick);
                        }
                    }
                }

                slider.classList.remove("active");
                isDragged = false;
            });

            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                isDragged = true;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 2;
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    };

    useEffect(() => {
        SettingDrag();
    }, []);

    return (
        <div
            id={`ArticleScroll${ArticleScrollIndex}`}
            className={`ArticleScroll articles Draggable${ArticleScrollIndex}`}
        >
            {vArticleData.map((item: iArticle, index: number) => {
                return <Article key={index + item.og_title} Article={item} />;
            })}
        </div>
    );
};

export default ArticleScroll;
