import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import PhoneVerify, { InputCodeNumber } from "../../components/Register/PhoneVerify";

const ForgetPhoneVerify = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sUserPhone, setUserPhone] = useState<string>("");
    const [sPhoneVerify, setPhoneVerify] = useState<InputCodeNumber>({
        "number-1": "",
        "number-2": "",
        "number-3": "",
        "number-4": "",
        "number-5": "",
        "number-6": "",
    });

    return (
        <div id="UserPhone">
            <Breadcrumb locationPath={location.pathname} />
            <div className="userphone-block">
                <div className="title">尋找您的密碼</div>
                <div className="userphone-block-content">輸入您註冊時驗證的手機號碼，以取得驗證碼</div>
                <PhoneVerify
                    sPhoneVerify={sPhoneVerify}
                    setPhoneVerify={setPhoneVerify}
                    sUserPhone={sUserPhone}
                    setUserPhone={setUserPhone}
                    successFn={() =>
                        navigate("/login/forgetstepone/forgetsteptwo", {
                            state: {
                                phone: sUserPhone,
                                code: Object.values(sPhoneVerify).join(""),
                            },
                        })
                    }
                />
            </div>
        </div>
    );
};

export default ForgetPhoneVerify;
