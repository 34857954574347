import { useContext, useEffect, useState } from 'react'
import BaseContext from '../../context/BaseContext'
import { postReport, postReportImage } from '../../util/api'
import { VerifyAlert } from '../../util/dataProcess'
import Button from '../Button'
import Input from '../controls/Input'
import { useForm } from '../controls/useForm'
import UploadImage from './UploadImage'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export interface reportPlatform {
    case_number: string
    case_date: string
    description: string
    [x: string]: any
}

const initReportPlatform = {
    case_number: '',
    case_date: '',
    description: '',
}

interface props {
    platform_code: string
}

const ReportPlatform = ({ platform_code }: props) => {
    const { sIdToken, setAlert } = useContext(BaseContext)

    const [vReportPlatform, setReportPlatform] = useState<reportPlatform>(initReportPlatform)
    const [vSelectedImages, setSelectedImages] = useState<Blob[]>([])

    const [startDate, setStartDate] = useState<Date | null>(null)
    const [startDateError, setStartDateError] = useState<boolean>(false)

    const { setLoading, errorProcess } = useContext(BaseContext)

    const { errors, setErrors, handleInputChange } = useForm(initReportPlatform, vReportPlatform, setReportPlatform)

    const taskDate = (dateMilli: Date | null) => {
        if (dateMilli === null) return ''
        const offset = dateMilli.getTimezoneOffset()
        return new Date(dateMilli.getTime() - offset * 60 * 1000).toISOString().split('T')[0].toString()
    }

    const validate = (fieldValues = vReportPlatform) => {
        let temp: reportPlatform = { ...(errors as reportPlatform) }
        if ('case_number' in fieldValues) temp.case_number = fieldValues.case_number === '' ? '此欄位必填' : ''
        console.log(taskDate(startDate))
        if (startDate === null) {
            setStartDateError(true)
        } else {
            setStartDateError(false)
        }
        setErrors({
            ...temp,
        })
        if (fieldValues === vReportPlatform) return Object.values(temp).every((x) => x === '')
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        let AllImageURL: string[] = []
        if (validate()) {
            if (vSelectedImages.length !== 0) {
                for (let index = 0; index < vSelectedImages.length; index++) {
                    setLoading(true)
                    let formData = new FormData()
                    formData.append('file', vSelectedImages[index])
                    await postReportImage(sIdToken.current, platform_code, formData)
                        .then((res: any) => {
                            if (res) {
                                AllImageURL.push(res.filepath)
                            }
                        })
                        .catch((errors) => {
                            console.log(errors)
                        })
                }
            }
            await postReport(sIdToken.current, platform_code, {
                case_number: vReportPlatform.case_number,
                case_date: taskDate(startDate),
                description: vReportPlatform.description,
                images: AllImageURL.join(','),
            })
                .then((res) => {
                    setLoading(false)
                    if (res.success) {
                        setAlert('Modal', false)
                        VerifyAlert({
                            title: '已將您的舉報傳送',
                            content: '舉報經確認後若屬實，我們將發送貢獻值表達感謝。',
                            btnText: '關閉',
                            MsgType: 1,
                            btnOnclick: () => {
                                setAlert('Msg', false)
                            },
                            setAlert: setAlert,
                        })
                    } else {
                        setAlert('Modal', false)
                        VerifyAlert({
                            title: '舉報失敗',
                            content: `${res.msg}`,
                            btnText: '返回',
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert('Msg', false)
                            },
                            setAlert: setAlert,
                        })
                    }
                })
                .catch((error: any) => {
                    setLoading(false)
                    console.error(error)
                    let error_status: number
                    if (error.response !== undefined) {
                        error_status = error.response.status
                    } else {
                        error_status = 0
                    }
                    errorProcess(error_status, error)
                })
        }
    }

    return (
        <div id="ReportPlatform">
            <div className="report-text">
                <Input
                    name="case_number"
                    label="違約案件編號"
                    value={vReportPlatform.case_number}
                    onChange={handleInputChange}
                    error={errors.case_number}
                    type={'text'}
                    hasPlaceholder={true}
                />

                <div className="report-platform-hint">請輸入您於該平台的案件編號</div>
                {/* 日期額外處理 */}
                <div className="date-input">
                    <DatePicker
                        className={`${startDateError ? 'error-date' : ''}`}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        disabledKeyboardNavigation
                        placeholderText="違約舉報日期"
                        dateFormat="yyyy - MM - dd"
                    />
                    <span className={`case-data-must-hint`}>(必填)</span>
                    {startDateError && (
                        <p className="warning_word">
                            <img src={process.env.PUBLIC_URL + '/img/icon-input-error.svg'} alt="" />
                            此欄位必填
                        </p>
                    )}
                </div>
                <div className="text-description-title">違約事由描述</div>
                <textarea
                    className="description"
                    name="description"
                    value={vReportPlatform.description}
                    onChange={handleInputChange}
                />
            </div>
            <div className="report-image">
                <div>
                    <div className="upload-image-title">上傳相關照片</div>
                    <div className="report-platform-hint">檔案上限 1 MB，最多可上傳 5 張</div>
                    <UploadImage vSelectedImages={vSelectedImages} setSelectedImages={setSelectedImages} />
                </div>
                <div className="image-upload-btn">
                    <Button isShowIcon={false} onclickFn={handleSubmit} btnName="提交" />
                </div>
            </div>
        </div>
    )
}

export default ReportPlatform
