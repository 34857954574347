import React from 'react'

interface props {
    name: string
    label: string
    value: string
    hasPlaceholder: boolean
    error: string | null
    onChange: (e: any) => void
    type: string
    inputdisable?: boolean
}

const Input = (props: props) => {
    const { name, label, value, error, hasPlaceholder, type, onChange, inputdisable = false } = props
    return (
        <div className="native-input-group">
            <input
                className={`${error !== '' && 'error_input'} 
                ${
                    !hasPlaceholder && label !== '行動電話'
                        ? 'has-float-text'
                        : label === '行動電話'
                        ? 'phone-input'
                        : ''
                } 
                ${type === 'date' ? 'date-input' : ''}  `}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={`${hasPlaceholder ? label : ''}`}
                disabled={inputdisable}
                pattern={type === 'number' ? '[0-9]*' : ''}
                inputMode={type === 'number' ? 'numeric' : 'text'}
            />
            {!hasPlaceholder && <span className="floating-label">{label}</span>}
            {label === '行動電話' && (
                <span className={`phone-country ${inputdisable ? 'input-disable' : ''}`}>+886｜</span>
            )}
            {(name === 'case_date' || name === 'case_number') && <span className={`must-hint`}>(必填)</span>}
            {/* {label === '生日' && value === '' && <span className={`birth`}>DD/MM/YYYY</span>} */}

            {error !== '' && (
                <p className="warning_word">
                    <img src={process.env.PUBLIC_URL + '/img/icon-input-error.svg'} alt="" />
                    {error}
                </p>
            )}
        </div>
    )
}

export default Input
