import { faPen } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import BaseContext from "../../context/BaseContext";
import Input from "../../components/controls/Input";
import { putUserName } from "../../util/api";
import Marquees from "../../components/Marquees";

const vTaskList = [
    {
        title: "推薦好友成功註冊，獲得貢獻值100點！",
        context: "～成功邀請好友註冊平台可獲得貢獻值『五倍』加成（限前300位）",
    },
    // {
    //     title: "完成任意三個P2P平台評分，貢獻值加碼送！",
    //     context: "完成任意三個平台評分，貢獻值加碼送 1000 點（限前300位）",
    // },
];

export default function UserPage() {
    const {
        ChangeURL,
        setAlert,
        bMsg,
        vUserInfo,
        sIdToken,
        errorProcess,
        updateUserInfo,
        checkTokenVerify,
        logoutNative,
    } = useContext(BaseContext);
    const [bShowCopy, setShowCopy] = useState<boolean>(false);
    const [sUserName, setUserName] = useState<string>(vUserInfo.name);

    const [iTimer, setTimer] = useState<number>(0);

    const handleModifyName = (e: any) => {
        setUserName(e.target.value);
    };

    const changeName = () => {
        putUserName(sIdToken.current, { name: sUserName })
            .then((res: any) => {
                if (res.success) {
                    // ChangeURL('/user')
                    setAlert("Msg", false);
                    //Update 使用者資訊
                    updateUserInfo(sIdToken.current);
                }
            })
            .catch((error: any) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };

    const modifyName = () => {
        const confirm_element = (
            <div id="ModifyNameModal">
                <Input
                    name="name"
                    label="暱稱"
                    value={sUserName}
                    onChange={(e) => handleModifyName(e)}
                    error={""}
                    type={"text"}
                    hasPlaceholder={false}
                />
                <div className="modify-btn">
                    <Button isShowIcon={false} onclickFn={changeName} btnName="完成" />
                </div>
                <div
                    className="close-modify"
                    onClick={() => {
                        setAlert("Msg", false);
                        setUserName(sUserName);
                    }}
                >
                    取消
                </div>
            </div>
        );
        setAlert("Msg", true, confirm_element, "更改用戶名稱", 999, false);
    };

    const countdown = () => {
        return setTimeout(() => {
            if (iTimer === 0) {
                setShowCopy(false);
            } else if (iTimer > 0) {
                setTimer((previTimer) => previTimer - 1);
            }
        }, 1000);
    };

    const copyStringEvent = (code: string) => {
        navigator.clipboard.writeText(
            `這是我的 P2PRank 專屬邀請碼 ${code}！跟我一起領取獎勵金吧！${window.location.origin}/login`
        );
        setShowCopy(true);
        setTimer(1);
    };

    const withdrawAply = () => {
        if (vUserInfo.has_bank_info) {
            //提領頁面
            ChangeURL("/withdrawapply");
        } else {
            //跳通知轉頁面
            const confirm_element = (
                <div id="WithReturnAlert">
                    <div className="title">請先登錄銀行帳戶</div>
                    <div className="WithReturnAlert-btn">
                        <Button
                            isShowIcon={false}
                            onclickFn={() => {
                                ChangeURL("/banksetting");
                                setAlert("Msg", false);
                            }}
                            btnName={"登錄帳戶"}
                        />
                    </div>
                    <div className="WithReturnAlert-return" onClick={() => setAlert("Msg", false)}>
                        返回個人頁
                    </div>
                </div>
            );
            setAlert("Msg", true, confirm_element, "", 4, false);
        }
    };

    useEffect(() => {
        let timeoutID = countdown();
        return () => {
            window.clearTimeout(timeoutID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iTimer]);

    useEffect(() => {
        if (bMsg) modifyName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sUserName]);

    useEffect(() => {
        setUserName(vUserInfo.name);
    }, [vUserInfo]);

    useEffect(() => {
        checkTokenVerify();
        document.title = "會員中心｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="UserPage">
            <div className="head"></div>
            <div className="user-page">
                <div className="user-page-block">
                    <div className="user-info">
                        <div className="user-img">
                            <img src={process.env.PUBLIC_URL + "/img/user-face.png"} alt="" />
                        </div>
                        <div className="user-name">
                            <span>{vUserInfo.name}</span>
                            <img
                                className="icon"
                                src={process.env.PUBLIC_URL + "/img/icon-edit.svg"}
                                alt=""
                                onClick={modifyName}
                            />
                            {/* <FontAwesomeIcon className="icon" icon={faPen as IconProp} onClick={modifyName} /> */}
                        </div>
                        <div className="user-invite-code">
                            <span>邀請碼</span>
                            <span id="copyInviteCode">{vUserInfo.share_code}</span>
                            <span onClick={() => copyStringEvent(vUserInfo.share_code)}>
                                <img src={process.env.PUBLIC_URL + "/img/icon-copy.svg"} alt="" />
                            </span>
                        </div>
                        <p className={`copy-tip ${bShowCopy ? "active" : ""}`}>已複製</p>
                        <div className="user-response">
                            <div>
                                <p>評分筆數</p>
                                <p>{vUserInfo.comment_count}</p>
                            </div>
                            <div>
                                <p>違約回報數</p>
                                <p>{vUserInfo.response_count}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sponsor sponsor-mobile">
                        <div>
                            <p className="sponsor-tile">貢獻值</p>
                            <div className="point-block">
                                <img src={process.env.PUBLIC_URL + "/img/coin.png"} alt="" />
                                <span className="point">{vUserInfo.points}</span>
                                <span className="unit">pts</span>
                            </div>
                        </div>
                        <button onClick={withdrawAply}>提領</button>
                    </div>
                    <div className="hot-activity hot-activity-mobile">
                        <div className="activity-title">
                            <p>熱門活動</p>
                        </div>
                        <div className="task-block">
                            {vTaskList.map((task, index) => {
                                return (
                                    <div className="task-item" key={index + "1"}>
                                        <div>
                                            <p className="task-item-title">{task.title}</p>
                                            <p className="task-item-content">{task.context}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="userpage-btn">
                        <Button
                            backgroundDark={true}
                            isShowIcon={false}
                            onclickFn={() => {
                                ChangeURL("/usermodify");
                            }}
                            btnName="修改帳號資料"
                        />
                    </div>
                    <div className="logout" onClick={logoutNative}>
                        登出
                    </div>
                </div>
                <div className="user-page-desktop-block">
                    <div className="sponsor sponsor-desktop">
                        <div>
                            <p className="sponsor-tile">貢獻值</p>
                            <div className="point-block">
                                <img src={process.env.PUBLIC_URL + "/img/coin.png"} alt="" />
                                <span className="point">{vUserInfo.points}</span>
                                <span className="unit">pts</span>
                            </div>
                        </div>
                        <button onClick={withdrawAply}>提領</button>
                    </div>
                    <div className="hot-activity hot-activity-desktop">
                        <div className="activity-title">
                            <p>熱門活動</p>
                        </div>
                        <div className="task-block">
                            {vTaskList.map((task, index) => {
                                return (
                                    <div className="task-item" key={index + "2"}>
                                        <div>
                                            <p className="task-item-title">{task.title}</p>
                                            <p className="task-item-content">{task.context}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Marquees />
        </div>
    );
}
