import { useContext, useEffect, useState } from "react";
import BaseContext from "../../context/BaseContext";
import Button from "../Button";
import Input from "../controls/Input";
import Select from "react-select";
import { bankInfo, useForm } from "../controls/useForm";
import { bankCode } from "../../util/BankData";
import { VerifyAlert } from "../../util/dataProcess";
import { putBankInfo } from "../../util/api";

const initialBankValues = {
    bankCode: "", //銀行代碼
    bankBranch: "", //銀行分行名稱
    bankAccount: "", //銀行帳號
    AccountName: "", //銀行戶名
    // birthday: "", //生日
};

const customStyles = {
    control: (styles: any) => ({
        ...styles,
        height: "58px",
        border: "1px solid #e2e2e2",
        textIndent: "5px",
        marginBottom: "15px",
    }),

    option: (provided: any, state: any) => ({
        ...provided,
        borderBottom: "1px solid #e0e0e0",
        fontSize: 16,
        padding: 10,
    }),
    input: (base: any) => ({
        ...base,
        paddingLeft: "5px",
    }),
};

interface props {
    clickBtnText: string;
    successFn: () => void;
    returnFn: () => void;
}

const BankSetting = ({ clickBtnText, successFn, returnFn }: props) => {
    const [vBankValue, setBankValue] = useState<bankInfo>(initialBankValues);

    const { vUserInfo, setAlert, sIdToken, errorProcess } = useContext(BaseContext);

    const { errors, setErrors, handleInputChange } = useForm(initialBankValues, vBankValue, setBankValue);

    useEffect(() => {
        if (vUserInfo.has_bank_info) {
            const vUserBank = {
                bankCode: vUserInfo.bank_code !== null ? vUserInfo.bank_code : "", //銀行代碼
                bankBranch: vUserInfo.bank_branch !== null ? vUserInfo.bank_branch : "", //銀行分行名稱
                bankAccount: vUserInfo.bank_account_number ? vUserInfo.bank_account_number : "", //銀行帳號
                AccountName: vUserInfo.bank_account_name ? vUserInfo.bank_account_name : "", //銀行戶名
                // birthday: vUserInfo.birth ? vUserInfo.birth : "",
            };
            setBankValue(vUserBank);
        }
    }, [vUserInfo]);

    const getValue = () => {
        return bankCode.filter((item) => item.value === vBankValue.bankCode);
    };

    const onChange = (newValue: any, actionMeta: any) => {
        setBankValue({
            ...vBankValue,
            bankCode: newValue.value,
        });
    };

    const validate = (fieldValues = vBankValue) => {
        let temp: bankInfo = { ...(errors as bankInfo) };
        if ("bankCode" in fieldValues) temp.bankCode = fieldValues.bankCode === "" ? "此欄位必填" : "";
        if ("bankBranch" in fieldValues) temp.bankBranch = fieldValues.bankBranch === "" ? "此欄位必填" : "";
        if ("bankAccount" in fieldValues)
            temp.bankAccount =
                fieldValues.bankAccount === ""
                    ? "此欄位必填"
                    : /^[0-9]{1,14}$/.test(fieldValues.bankAccount) === false
                    ? "請輸入有效銀行14碼帳號"
                    : "";
        if ("AccountName" in fieldValues) temp.AccountName = fieldValues.AccountName === "" ? "此欄位必填" : "";
        // if ('birthday' in fieldValues) temp.birthday = fieldValues.birthday === '' ? '此欄位必填' : ''
        setErrors({
            ...temp,
        });
        if (fieldValues === vBankValue) return Object.values(temp).every((x) => x === "");
    };

    const validbankcode = (code: string) => {
        const zerolen = 14 - code.length;
        return "0".repeat(zerolen) + code;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate()) {
            putBankInfo(sIdToken.current, {
                bank_code: vBankValue.bankCode,
                bank_branch: vBankValue.bankBranch,
                bank_account_number:
                    vBankValue.bankAccount.length < 14 ? validbankcode(vBankValue.bankAccount) : vBankValue.bankAccount,
                bank_account_name: vBankValue.AccountName,
                // birth: vBankValue.birthday,
            })
                .then((res: any) => {
                    if (res.success) {
                        successFn();
                    } else {
                        VerifyAlert({
                            title: "更新失敗",
                            content: `${res.msg}`,
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    return (
        <div className="bank-setting">
            <Select
                className="select"
                placeholder={"銀行代碼"}
                formatOptionLabel={(option) => `${option.value} ${option.label}`}
                options={bankCode}
                value={getValue()}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    height: "58px",
                })}
                onChange={onChange}
            />
            <Input
                name="bankBranch"
                label="分行名稱"
                value={vBankValue.bankBranch}
                onChange={(e) => handleInputChange(e)}
                error={errors.bankBranch}
                type={"text"}
                hasPlaceholder={true}
            />
            <Input
                name="bankAccount"
                label="銀行帳號"
                value={vBankValue.bankAccount}
                onChange={(e) => handleInputChange(e)}
                error={errors.bankAccount}
                type={"number"}
                hasPlaceholder={true}
            />
            <Input
                name="AccountName"
                label="戶名"
                value={vBankValue.AccountName}
                onChange={(e) => handleInputChange(e)}
                error={errors.AccountName}
                type={"text"}
                hasPlaceholder={true}
            />
            {/* <Input
                name="birthday"
                label="生日"
                value={vBankValue.birthday}
                onChange={(e) => handleInputChange(e)}
                error={errors.birthday}
                type={'date'}
                hasPlaceholder={false}
            /> */}
            <div className="modify-btn">
                <Button isShowIcon={false} btnName={clickBtnText} onclickFn={(e) => handleSubmit(e)} />
            </div>
            <div className="cancel" onClick={returnFn}>
                取消
            </div>
        </div>
    );
};

export default BankSetting;
