import { useEffect, useState } from 'react'
import { iCommentCard } from '../../pages/ScorePage'
import moment, { Duration } from 'moment'

interface props {
    UsrComment: iCommentCard
}

const CommentCard = ({ UsrComment }: props) => {
    const [bShowMore, setShowMore] = useState<boolean>(false)
    // const [bShowMoreText, setShowMoreText] = useState<boolean>(true);

    useEffect(() => {
        if (UsrComment.comment === null) return
        if (UsrComment.comment.length > 80) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const calculateDate = (date: string) => {
        const now: Date = new Date(moment().utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss'))
        const last: Date = new Date(date)
        let diff: Duration = moment.duration(moment(now).diff(moment(last)))
        let minutes: number = diff.asMinutes()
        if (minutes < 1) {
            return '剛剛'
        } else if (minutes < 60) {
            return Math.ceil(minutes) + '分鐘前'
        } else if (minutes < 1440) {
            return Math.ceil(minutes / 60) + '小時前'
        } else if (minutes < 1440 * 7) {
            return Math.ceil(minutes / 1440) + '天前'
        } else {
            return date.substring(0, 10)
        }
    }

    return (
        <>
            {UsrComment.comment !== null && (
                <div className="comment-card">
                    <div className="comment-card-block">
                        <div className="user-info">
                            <div className="user-img">
                                <img src={process.env.PUBLIC_URL + '/img/user-face.png'} alt="" />
                            </div>
                            <div>
                                <div className="user-name">
                                    <div>{UsrComment.user_name}</div>
                                    {/* 暫時不開放評論舉報 */}
                                    {/* <div className="report">舉報</div> */}
                                </div>
                                <div className="comment-date">{calculateDate(UsrComment.created_at)}</div>
                            </div>
                        </div>
                        <p
                            id={`user-comment` + UsrComment.user_name + UsrComment.created_at}
                            className={`user-comment`}
                        >
                            {bShowMore ? UsrComment.comment.slice(0, 80) + '...' : UsrComment.comment}
                        </p>
                        {bShowMore && (
                            <label className="show-more" onClick={() => setShowMore(!bShowMore)}>
                                {bShowMore ? `顯示全文` : ``}
                            </label>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default CommentCard
