import React, { useContext, useState } from 'react'
import { accountCheck, useForm } from '../../components/controls/useForm'
import Input from '../../components/controls/Input'
import { nativeLoginInput } from '.'
import Button from '../../components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import BaseContext from '../../context/BaseContext'

const initialValues: accountCheck = {
    account: '',
    password: '',
}

const LoginNative = (props: nativeLoginInput) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { click_login } = props

    const { ChangeURL, setAlert } = useContext(BaseContext)

    const [values, setValues] = useState<accountCheck>(initialValues)
    // // login
    // const [sAccountLogin, setsAccountLogin] = useState("");
    // const [bCheckAccountLogin, setbCheckAccountLogin] = useState(false);
    // const [sPasswordLogin, setsPasswordLogin] = useState("");
    // const [bCheckPasswordLogin, setbCheckPasswordLogin] = useState(false);

    // // allow to submit
    // const checkSendBtn = () => {
    //     return !bCheckAccountLogin && !bCheckPasswordLogin && sAccountLogin !== "" && sPasswordLogin !== "";
    // };

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('account' in fieldValues)
            temp.account =
                fieldValues.account === ''
                    ? '此欄位必填'
                    : /$^|.+@.+..+/.test(fieldValues.account)
                    ? ''
                    : '請輸入有效的帳號'
        if ('password' in fieldValues) temp.password = fieldValues.password === '' ? '此欄位必填' : ''
        setErrors({
            ...temp,
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { errors, setErrors, handleInputChange } = useForm(initialValues, values, setValues)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        validate()
        if (validate()) {
            // resetForm()
            click_login({ email: values.account, password: values.password })
        }
    }

    const changeToRegister = () => {
        navigate('/login/register', {
            state: {
                name: '',
                email: '',
                id: '',
                from: '',
            },
        })
        window.localStorage.setItem('beLogin', 'false')
        if (location.pathname.indexOf('rankpage') > -1) {
            setAlert('Modal', false)
        }
    }
    // press enter to submit
    const keyListener = (e: React.KeyboardEvent) => {
        if (e.keyCode !== 13) return
        handleSubmit(e)
    }
    return (
        <div className={`login-input`} onKeyDown={(e) => keyListener(e)}>
            <Input
                name="account"
                label="電子郵件地址"
                value={values.account}
                onChange={(e) => handleInputChange(e)}
                error={errors.account}
                type={'text'}
                hasPlaceholder={true}
            />
            <Input
                name="password"
                label="密碼"
                value={values.password}
                onChange={(e) => handleInputChange(e)}
                error={errors.password}
                type={'password'}
                hasPlaceholder={true}
            />
            <div
                className="forget"
                onClick={() => {
                    ChangeURL('/login/forgetstepone')
                }}
            >
                忘記密碼
            </div>
            <div className="login-page-btn">
                <Button isShowIcon={false} btnName="註冊帳號" onclickFn={changeToRegister} />
                <Button isShowIcon={false} btnName="登入" onclickFn={(e: any) => handleSubmit(e)} />
            </div>
        </div>
    )
}

export default LoginNative
