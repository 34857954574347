import { useContext, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import BaseContext from '../../context/BaseContext'

const LoginFacebook = () => {
    const { loginFaceBook } = useContext(BaseContext)
    const responseFacebook = (response: any) => {
        if (response.status === 'unknown') {
            console.log('Login failed!')
        }
        if (response.accessToken) {
            //Login use Facebook
            loginFaceBook(response.email, response.name, response.id)
        }
    }

    const failLogin = (response: any) => {
        console.log(response)
    }

    return (
        <div className="facbook-login-input">
            <FacebookLogin
                appId="362622019145136"
                fields="name,email,picture"
                scope="public_profile,email,user_friends"
                callback={responseFacebook}
                onFailure={failLogin}
                cssClass="btnFacebook"
                textButton="Facebook 登入"
                redirectUri="/login"
                isMobile={false}
                icon={
                    <i className="icon ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.921"
                            height="22.158"
                            viewBox="0 0 11.921 22.158"
                        >
                            <path
                                id="Icon_zocial-facebook"
                                data-name="Icon zocial-facebook"
                                d="M13.257,11.9V7.667H16.67V5.539a5.568,5.568,0,0,1,1.495-3.922A4.756,4.756,0,0,1,21.788,0h3.39V4.232h-3.39a.747.747,0,0,0-.6.366,1.485,1.485,0,0,0-.266.9V7.667h4.254V11.9H20.924v10.26H16.669V11.9Z"
                                transform="translate(-13.257)"
                                fill="#1776f1"
                            />
                        </svg>
                    </i>
                }
            />
        </div>
    )
}

export default LoginFacebook
