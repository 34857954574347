import React, { useEffect, useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

interface props {
    monitorStatus: number;
    ReportPlatformModal: () => void;
}

const Monitor = ({ monitorStatus, ReportPlatformModal }: props) => {
    const MonitorRef = useRef<any>(null);
    const [bShowMonitor, setShowMonitor] = useState<boolean>(false);
    const MonitorType = [
        {
            color: "#5EC497",
            title: "良好",
            content: "該平台近期無違約案件。",
            image: "icon-check-circle.svg",
        },
        {
            color: "#F89B00",
            title: "不佳",
            content: "該平台近期有數筆違約案件。",
            image: "Icon-danger.svg",
        },
        {
            color: "#D0463E",
            title: "危險",
            content: "該平台近期有多筆違約案件。",
            image: "icon-bad.svg",
        },
    ];

    const style = {
        backgroundColor: MonitorType[monitorStatus === 0 ? 1 : monitorStatus - 1].color,
        width: "12px",
        height: "12px",
        borderRadius: "50%",
    };

    const iconstyle =
        monitorStatus === 1
            ? {
                  filter: "invert(63%) sepia(48%) saturate(399%) hue-rotate(101deg) brightness(101%) contrast(83%)",
              }
            : monitorStatus === 2
            ? { filter: "invert(54%) sepia(90%) saturate(1903%) hue-rotate(9deg) brightness(105%) contrast(103%)" }
            : { filter: "invert(41%) sepia(94%) saturate(2268%) hue-rotate(335deg) brightness(84%) contrast(92%)" };

    function handleClickOutside(event: any) {
        if (MonitorRef.current && !MonitorRef.current.contains(event.target)) {
            setShowMonitor(false);
        }
    }

    useEffect(() => {
        document.body.addEventListener("click", handleClickOutside);
        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [MonitorRef]);

    return (
        <div id="Monitor" ref={MonitorRef}>
            {monitorStatus === 0 ? (
                <></>
            ) : (
                <Tippy
                    zIndex={997}
                    visible={bShowMonitor}
                    animation="scale"
                    placement="bottom"
                    theme={"light"}
                    appendTo={"parent"}
                    maxWidth={"250px"}
                    delay={500}
                    content={
                        <>
                            <div className="monitor-content-title">
                                <img
                                    className="monitor-content-img"
                                    style={iconstyle}
                                    src={process.env.PUBLIC_URL + "/img/" + MonitorType[monitorStatus - 1].image}
                                    alt=""
                                />
                                <div className="monitor-state">
                                    違約監控狀態：{MonitorType[monitorStatus - 1].title}
                                </div>
                                <div className="monitor-content">{MonitorType[monitorStatus - 1].content}</div>
                            </div>
                            <div className="monitor-explain">
                                <div className="monitor-explain-content">
                                    回報平台違約案件，經查證屬實可獲得貢獻值獎勵。
                                </div>
                                <div
                                    className="monitor-explain-report"
                                    onClick={() => {
                                        ReportPlatformModal();
                                        setShowMonitor(false);
                                    }}
                                >
                                    舉報違約案件
                                    <img src={process.env.PUBLIC_URL + "/img/icon-chevron-right.svg"} alt="" />
                                </div>
                            </div>
                        </>
                    }
                >
                    <div
                        className="monitor-label"
                        onClick={() => {
                            setShowMonitor(!bShowMonitor);
                        }}
                    >
                        <div className="circle-icon" style={style}></div>
                        <div>違約監控：{MonitorType[monitorStatus - 1].title}</div>
                    </div>
                </Tippy>
            )}
        </div>
    );
};

export default Monitor;
