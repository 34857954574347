import { useContext } from "react";
import BaseContext from "../../context/BaseContext";
import { platform } from "../../util/api";

interface props {
    plat: platform;
}

const PlatCard = ({ plat }: props) => {
    const { ChangeURL } = useContext(BaseContext);
    return (
        <div className="PlatCard" onClick={() => ChangeURL("/rankpage/" + plat.platform_code)}>
            <img src={plat.image} alt="" />
            <div className="plat-info">
                <div className="background-blur">
                    <div>P2P平台</div>
                </div>
                <div className="plat-info-name">{plat.platform_name}</div>
            </div>
        </div>
    );
};

export default PlatCard;
