import React, { createContext } from 'react'
import { UserInfo } from '../App'
import { iArticle } from '../pages/BlogPage'

export const vUserInfoDefault: UserInfo = {
    name: '',
    user_id: null,
    user_code: '',
    phone: '',
    share_code: '',
    email: '',
    points: 0,
    photo: '',
    bank_code: null,
    bank_branch: null,
    bank_account_number: null,
    bank_account_name: null,
    birth: null,
    comment_count: 0,
    response_count: 0,
    has_bank_info: false,
}

const vArticleData: iArticle[] = []

const BaseContext = createContext({
    bClickBg: false,
    bModal: false,
    sModal: <></>,
    sModalTitle: '',
    setbModal: (x: boolean) => {},
    setsModal: (x: JSX.Element) => {},
    setsModalTitle: (x: string) => {},

    bMsg: false,
    sMsg: <></>,
    sMsgTitle: '',
    iMsgType: 1,
    setiMsgType: (x: number) => {},
    setbMsg: (x: boolean) => {},
    setsMsg: (x: JSX.Element) => {},
    setsMsgTitle: (x: string) => {},

    loading: false,
    setLoading: (x: boolean) => {},

    bCloseIcon: true,
    setAlert: (
        type: string,
        bAlert: boolean,
        sAlert: JSX.Element = <></>,
        sAlertTitle: string = '',
        iMsgType: number = 1,
        bCloseIcon: boolean = true,
        clickBg: boolean = false
    ) => {},

    vUserInfo: vUserInfoDefault,
    bAuth: { current: false } as React.MutableRefObject<boolean>,
    beLogin: { current: false } as React.MutableRefObject<boolean>,
    sIdToken: { current: '' } as React.MutableRefObject<string>,

    vArticleData: vArticleData,

    sBanner: {
        img: '',
        url: '',
    },

    ChangeURL: (url: string) => {},
    updateNativeToken: (native_token: string) => {},
    loginOauth: (id_token: string, access_token: string, refresh_token: string) => {},
    loginFaceBook: (email: string, name: string, id: string) => {},
    nativeLogin: (account: { email: string; password: string }) => {},
    logoutNative: () => {},
    errorProcess: (errorStatus: number, errorMes: any) => {},
    checkTokenVerify: () => {},
    updateUserInfo: (native_token: string) => {},
})

export const { Provider } = BaseContext
export default BaseContext
