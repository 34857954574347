import { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import BaseContext from "../context/BaseContext";

/* url fit path */
const breadcrumbNameMap = [
    //  [path, breadcrumbName, page name]
    ["/", "首頁", "/"],
    ["/blog", "部落格", "blog"],
    ["/login", "會員登入", "login"],
    ["/login/register", "會員註冊", "register"],
    ["/login/register/invite", "輸入邀請碼", "invite"],
    ["/login/register/invite/phone", "驗證手機號碼", "phone"],
    ["/rankpage", "平台排名總覽", "rankpage"],
    ["/contact", "聯絡我們", "contact"],
    ["/about", "關於我們", "about"],
    ["/login/forgetstepone", "忘記密碼", "forgetstepone"],
    ["/login/forgetstepone/forgetsteptwo", "重設您的密碼", "forgetsteptwo"],
    ["/termsandconditions", "服務條款", "termsandconditions"],
    ["/privacypolicy", "隱私權政策", "privacypolicy"],
    ["/search", "搜尋頁面", "search"],
];

interface props {
    needHome?: boolean;
    locationPath: string;
}

function Breadcrumb({ needHome = false, locationPath }: props) {
    const { beLogin } = useContext(BaseContext);

    const Pathtemp = locationPath.split("/");
    const breadStep: any = [];

    if (needHome) {
        //加入首頁
        breadStep.push([breadcrumbNameMap[0][0], breadcrumbNameMap[0][1]]);
    }
    Pathtemp.forEach((item) => {
        breadcrumbNameMap.forEach((url) => {
            if (url[2] === item) {
                /* 0 :url 1 :breadName */
                let urltemp = beLogin.current && url[1] !== "會員註冊" ? url[0].replace("/register", "") : url[0];
                breadStep.push([urltemp, url[1]]);
            }
        });
    });
    useEffect(() => {
        const localBeLogin = window.localStorage.getItem("beLogin");
        if (localBeLogin !== undefined) {
            if (localBeLogin === "true") {
                beLogin.current = true;
            } else {
                beLogin.current = false;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ol className="breadcrumb">
            {breadStep.map((matchRoute: any, i: number) => {
                return (
                    <Fragment key={i + matchRoute[1]}>
                        {i === breadStep.length - 1 ? (
                            <li>
                                <span className="breadcrumb-item-not-active">{matchRoute[1]}</span>
                            </li>
                        ) : (
                            <li className="breadcrumb-item">
                                <Link to={matchRoute[0]}>{matchRoute[1]} </Link>
                            </li>
                        )}
                        {i !== breadStep.length - 1 && (
                            <img src={process.env.PUBLIC_URL + "/img/icon-bread-arrow.svg"} alt="" />
                        )}
                    </Fragment>
                );
            })}
        </ol>
    );
}

export default Breadcrumb;
