import { useContext, useEffect } from "react";
import Button from "../../components/Button";
import BankSetting from "../../components/UserPage/BankSetting";
import BaseContext from "../../context/BaseContext";

const BankSettingPage = () => {
    const { sIdToken, updateUserInfo, ChangeURL, setAlert } = useContext(BaseContext);

    const bankSuccessFN = () => {
        updateUserInfo(sIdToken.current);
        const confirm_element = (
            <div id="WithReturnAlert">
                <div className="title">登錄完成</div>
                <div className="WithReturnAlert-btn">
                    <Button
                        isShowIcon={false}
                        onclickFn={() => {
                            ChangeURL("/withdrawapply");
                            setAlert("Msg", false);
                        }}
                        btnName={"繼續提領貢獻直"}
                    />
                </div>
                <div className="WithReturnAlert-return" onClick={() => setAlert("Msg", false)}>
                    返回個人頁
                </div>
            </div>
        );
        setAlert("Msg", true, confirm_element, "", 1, false);
    };

    const bankReturnFN = () => {
        ChangeURL("/user");
    };

    useEffect(() => {
        document.title = "銀行帳戶資料編輯｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="BankSettingPage">
            <div className="bank-setting-block">
                <div className="title">銀行帳戶資料</div>
                <div className="bank-block">
                    <BankSetting clickBtnText={"儲存並送出"} successFn={bankSuccessFN} returnFn={bankReturnFN} />
                </div>
            </div>
        </div>
    );
};

export default BankSettingPage;
