import React from 'react'
interface props {
    companyName: string
    year: number
    investment_type: string
    location: string
}

const CompanyInfo = ({ companyName, year, investment_type, location }: props) => {
    return (
        <div id="company-info">
            <div className="info-title">公司名稱</div>
            <div className="info">{companyName}</div>
            <div className="info-title">成立年份</div>
            <div className="info">{year}</div>
            <div className="info-title">投資類型</div>
            <div className="info">{investment_type}</div>
            <div className="info-title">總部</div>
            <div className="info">{location}</div>
        </div>
    )
}

export default CompanyInfo
