import React, { useContext, useState } from "react";
import BaseContext from "../../context/BaseContext";

interface prop {
    divID?: string;
}

const SearchBar = ({ divID = "SearchBar" }: prop) => {
    const [sTextSearch, setTextSearch] = useState<string>("");

    const { ChangeURL } = useContext(BaseContext);
    const Keypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        var key = e.which || e.keyCode || 0;
        if (key === 13 && sTextSearch !== "") {
            //enter
            ChangeURL("/search/" + sTextSearch);
            setTextSearch("");
            const temp = document.getElementById("Search-nav");
            if (temp) temp.style.display = "none";
        }
    };
    const handleChange = (e: any) => {
        setTextSearch(e.target.value);
    };
    return (
        <input
            value={sTextSearch}
            onKeyPress={(e) => Keypress(e)}
            onChange={handleChange}
            id={divID}
            className="search"
            type="search"
            placeholder="&#xF002;  搜尋平台、P2P相關內容"
            enterKeyHint={"search"}
        />
    );
};

export default SearchBar;
