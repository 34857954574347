import { useContext, useState } from "react";
import BaseContext from "../../context/BaseContext";
import { putUserPassword } from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";
import Button from "../Button";
import Input from "../controls/Input";
import { modifyPassword, useForm } from "../controls/useForm";

const initialPassWordValues: modifyPassword = {
    old_password: "",
    new_password: "",
};

const ModifyPassword = () => {
    const [vModifyValue, setModifyValue] = useState<modifyPassword>(initialPassWordValues);
    const [vDoublecheckPass, setDoublecheckPass] = useState<string>("");
    const [vDoublecheckPassError, setDoublecheckPassError] = useState<string>("");

    const { ChangeURL, setAlert, vUserInfo, sIdToken, errorProcess } = useContext(BaseContext);

    const { errors, setErrors, handleInputChange } = useForm(initialPassWordValues, vModifyValue, setModifyValue);

    const handleDoublecheckPassInputChange = (e: any) => {
        setDoublecheckPass(e.target.value);
    };

    const validate = (fieldValues = vModifyValue) => {
        let temp: modifyPassword = { ...(errors as modifyPassword) };
        if ("old_password" in fieldValues) temp.old_password = fieldValues.old_password === "" ? "此欄位必填" : "";
        if ("new_password" in fieldValues)
            temp.new_password =
                fieldValues.new_password === ""
                    ? "此欄位必填"
                    : /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/.test(fieldValues.new_password) === false
                    ? "請混合使用 8 個字元以上的英文字母、數字和符號"
                    : fieldValues.new_password === fieldValues.old_password
                    ? "新密碼不可與舊密碼相同"
                    : "";
        setErrors({
            ...temp,
        });
        if (fieldValues === vModifyValue) return Object.values(temp).every((x) => x === "");
    };

    const checkDoublePassword = () => {
        if (vDoublecheckPass === "") {
            setDoublecheckPassError("此欄位必填");
        } else if (vModifyValue.new_password !== vDoublecheckPass) {
            setDoublecheckPassError("與新密碼不同");
        } else {
            setDoublecheckPassError("");
        }
        return vModifyValue.new_password === vDoublecheckPass;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate() && checkDoublePassword()) {
            //還要檢查密碼是否相同
            putUserPassword(sIdToken.current, {
                old_password: vModifyValue.old_password,
                new_password: vModifyValue.new_password,
                new_password2: vDoublecheckPass,
            })
                .then((res: any) => {
                    if (res.success) {
                        VerifyAlert({
                            title: "更新成功",
                            content: "",
                            btnText: "返回個人頁",
                            MsgType: 1,
                            btnOnclick: () => {
                                ChangeURL("/user");
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    } else {
                        VerifyAlert({
                            title: "更新失敗",
                            content: `${res.msg}`,
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setModifyValue(initialPassWordValues);
                                setDoublecheckPass("");
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    return (
        <div className="ModifyPassword">
            <div>
                <Input
                    name="email"
                    label="電子郵件地址"
                    value={vUserInfo.email}
                    onChange={() => {}}
                    error={""}
                    type={"text"}
                    hasPlaceholder={false}
                    inputdisable={true}
                />
                <Input
                    name="phone"
                    label="行動電話"
                    value={vUserInfo.phone}
                    onChange={() => {}}
                    error={""}
                    type={"text"}
                    hasPlaceholder={false}
                    inputdisable={true}
                />
                <Input
                    name="old_password"
                    label="舊密碼"
                    value={vModifyValue.old_password}
                    onChange={(e) => handleInputChange(e)}
                    error={errors.old_password}
                    type={"password"}
                    hasPlaceholder={false}
                />

                <Input
                    name="new_password"
                    label="新密碼"
                    value={vModifyValue.new_password}
                    onChange={(e) => handleInputChange(e)}
                    error={errors.new_password}
                    type={"password"}
                    hasPlaceholder={false}
                />
                <p className="hint">請混合使用 8 個字元以上的英文字母、數字和符號</p>
                <Input
                    name="password"
                    label="確認密碼"
                    value={vDoublecheckPass}
                    onChange={(e) => handleDoublecheckPassInputChange(e)}
                    error={vDoublecheckPassError}
                    type={"password"}
                    hasPlaceholder={false}
                />
                <div className="modify-btn">
                    <Button isShowIcon={false} btnName="儲存並更新" onclickFn={(e) => handleSubmit(e)} />
                </div>
                <div className="cancel" onClick={() => ChangeURL("/user")}>
                    取消
                </div>
            </div>
        </div>
    );
};

export default ModifyPassword;
