import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { filter, FilterTab, iArticle } from "../../pages/BlogPage";
import ArticleScroll from "./ArticleScroll";

interface props {
    vArticleData: iArticle[];
    setClassName: React.Dispatch<React.SetStateAction<number>>;
}

/*blog-1 page*/
const BlogArticleClass = ({ vArticleData, setClassName }: props) => {
    const seeMoreSet = (articleType: number) => {
        setClassName(articleType);
    };

    return (
        <>
            {FilterTab.map((tab: filter, index: number) => {
                if (tab.Type === 999) return <></>; //全部類別不需顯示
                if (vArticleData.filter((item) => item.article_type === tab.Type).length === 0) return <></>;
                return (
                    <div key={tab.Type + index} className="article-block">
                        <div className="article-block-title">{tab.button_text}</div>
                        <ArticleScroll
                            ArticleScrollIndex={index}
                            vArticleData={vArticleData.filter((item) => item.article_type === tab.Type)}
                        />
                        <div className="see-more" onClick={() => seeMoreSet(tab.Type)}>
                            <span>查看更多</span>
                            <img src={process.env.PUBLIC_URL + "/img/icon-chevron-right.svg"} alt="" />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default BlogArticleClass;
