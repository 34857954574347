import { useContext, useEffect, useState } from "react";
import ArticleScroll from "../components/Blog/ArticleScroll";
import Button from "../components/Button";
import SearchBar from "../components/controls/SearchBar";
import Marquees from "../components/Marquees";
import RankCard from "../components/Rank/RankCard";
import BaseContext from "../context/BaseContext";
import { getPlatforms, platform, platform_all } from "../util/api";
import { VerifyAlert } from "../util/dataProcess";

export interface iScoreInfo {
    img: string;
    title: string;
    content: string;
    hint?: {
        subTitle: string;
        text: string;
    };
}

export interface iRankCard {
    name: string;
    point: string;
    icon: string;
}

const HomePage = () => {
    // context
    const { ChangeURL, checkTokenVerify, setAlert, vArticleData, sBanner } = useContext(BaseContext);
    const [vPlatform, setPlatform] = useState<platform[]>([]);

    const [bShowPlatRank, setShowPlatRank] = useState<boolean>(true);

    const getAllPlatform = () => {
        getPlatforms().then((res: platform_all) => {
            if (res.success) {
                sortList(res.platforms);
            } else {
                VerifyAlert({
                    title: "拿取平台資料失敗！",
                    content: ``,
                    btnText: "返回",
                    MsgType: 2,
                    btnOnclick: () => {
                        ChangeURL("/");
                        setAlert("Msg", false);
                    },
                    setAlert: setAlert,
                });
            }
        });
    };

    const sortList = (vPlat: platform[]) => {
        if (vPlat.length !== 0) {
            let tempPlatform: platform[] = JSON.parse(JSON.stringify(vPlat));
            tempPlatform.sort((a: platform, b: platform) => b.score.total - a.score.total);
            setPlatform(tempPlatform);
            checkShowPlatRank(tempPlatform);
        }
    };

    const checkShowPlatRank = (tempPlatform: platform[]) => {
        tempPlatform.slice(0, 3).forEach((item: platform) => {
            if (item.rank === 0) {
                setShowPlatRank(false);
            }
        });
    };

    useEffect(() => {
        checkTokenVerify();
        getAllPlatform();
        document.title = "台灣P2P平台排行 - P2P資訊集散地｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="Home">
            <div className="homepage-block">
                <div className="homepage-block-info-search">
                    <div className="homepage-block-title">台灣P2P平台排行</div>
                    <div className="homepage-block-content">
                        P2PRank由上千位投資人及借款人雙向評分，快速找出最適合您的借貸平台。
                    </div>
                    <div className="search-block">
                        <SearchBar />
                    </div>
                </div>
                <div className="homepage-block-pic">
                    {vPlatform.length > 4 /*&& bShowPlatRank*/ && (
                        <div className="homepage-block-rank">
                            <div
                                className="third-plat"
                                onClick={() => ChangeURL("/rankpage/" + vPlatform[2].platform_code)}
                            >
                                <div className="company-img">
                                    <img src={vPlatform[2].image} alt="" />
                                </div>
                                <div className="plat-score">
                                    <img
                                        className="icon-star"
                                        src={process.env.PUBLIC_URL + "/img/icon-star-black.svg"}
                                        alt=""
                                    />
                                    <div className="first-score">{vPlatform[2].score.total}</div>
                                    <div className="second-score">/5.0</div>
                                </div>
                            </div>
                            <div
                                className="first-plat"
                                onClick={() => ChangeURL("/rankpage/" + vPlatform[0].platform_code)}
                            >
                                <div className="company-img">
                                    <img src={vPlatform[0].image} alt="" />
                                </div>
                                <div className="plat-score">
                                    <img
                                        className="icon-star"
                                        src={process.env.PUBLIC_URL + "/img/icon-star-black.svg"}
                                        alt=""
                                    />
                                    <div className="first-score">{vPlatform[0].score.total}</div>
                                    <div className="second-score">/5.0</div>
                                </div>
                            </div>
                            <div
                                className="second-plat"
                                onClick={() => ChangeURL("/rankpage/" + vPlatform[1].platform_code)}
                            >
                                <div className="company-img">
                                    <img src={vPlatform[1].image} alt="" />
                                </div>
                                <div className="plat-score">
                                    <img
                                        className="icon-star"
                                        src={process.env.PUBLIC_URL + "/img/icon-star-black.svg"}
                                        alt=""
                                    />
                                    <div className="first-score">{vPlatform[1].score.total}</div>
                                    <div className="second-score">/5.0</div>
                                </div>
                            </div>
                            <div
                                className="four-plat"
                                onClick={() => ChangeURL("/rankpage/" + vPlatform[3].platform_code)}
                            >
                                <div className="company-img">
                                    <img src={vPlatform[3].image} alt="" />
                                </div>
                                <div className="plat-score">
                                    <img
                                        className="icon-star"
                                        src={process.env.PUBLIC_URL + "/img/icon-star-black.svg"}
                                        alt=""
                                    />
                                    <div className="first-score">{vPlatform[3].score.total}</div>
                                    <div className="second-score">/5.0</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <img src={process.env.PUBLIC_URL + "/img/pic-ranking.png"} alt="" />
                </div>
            </div>
            <div className="rank-score-block">
                <div className="rank-score-block-textfield">
                    <div className="rank-card-desktop">
                        <img src={process.env.PUBLIC_URL + "/img/pic-rank.png"} alt="" />
                    </div>
                    <div className="rank-card-right-side">
                        <div className="info">
                            <div className="info-hint">如何選擇 P2P 投資平台？</div>
                            <h1 className="title">依據平台用戶的評價排名</h1>
                            <div className="content-text">
                                我們蒐集了上千位用戶的體驗評價，來揭露各大平台最真實的營運狀況，當您在挑選平台時，為您提供最有參考價值的即時數據。
                            </div>
                        </div>
                        <div className="rank-card-mobile">
                            <img src={process.env.PUBLIC_URL + "/img/pic-rank.png"} alt="" />
                            {/* {vPlatform.length > 0 && <RankCard vPlatform={vPlatform.slice(0, 3)} filterType={999} />} */}
                        </div>
                        <div className="rank-score-block-btn">
                            <Button
                                isShowIcon={false}
                                onclickFn={() => ChangeURL("/rankpage")}
                                btnName="更多 P2P 平台排名"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="article-block">
                <div className="info">
                    <h1 className="title">台灣最大的P2P資訊集散地</h1>
                    <div className="content-text">走在台灣P2P的最前端，即時掌握第一手趨勢，投資不落人後。</div>
                </div>
            </div>
            <div className="article-scroll-block">
                <ArticleScroll vArticleData={vArticleData} ArticleScrollIndex={1} />
            </div>
            <div className="use-app-start">
                <div className="use-app-start-block">
                    <div className="content-block">
                        <h1 className="title">完成平台評分獲得貢獻值</h1>
                        <div className="content-text">
                            完成評分獲得貢獻值，與我們一起保護P2P的投資市場，現在就開始您的第一次評分。
                        </div>
                        <div className="content-title-img-mobile">
                            <img src={process.env.PUBLIC_URL + "/img/pic-userbenifit.png"} alt="" />
                        </div>
                        <div className="content-subtitle-block">
                            <img className="content-img-notmt" src={process.env.PUBLIC_URL + "/img/info.png"} alt="" />
                            <div>
                                <div className="subtitle">完成身份驗證</div>
                                <div className="content-text">完成身份驗證的程序，以共同維護真實性與公信力。</div>
                            </div>
                        </div>
                        <div className="content-subtitle-block">
                            <img className="content-img" src={process.env.PUBLIC_URL + "/img/data.png"} alt="" />
                            <div>
                                <div className="subtitle">選擇平台開始評分</div>
                                <div className="content-text">
                                    我們目前擁有 10
                                    間以上的台灣的主流P2P平台，選擇您使用過的平台，並依照您的經驗進行評分。
                                </div>
                            </div>
                        </div>
                        <div className="content-subtitle-block">
                            <img className="content-img" src={process.env.PUBLIC_URL + "/img/score-coin.png"} alt="" />
                            <div>
                                <div className="subtitle">完成即可獲得貢獻值</div>
                                <div className="content-text">評分完成後，即可獲得貢獻值獎勵！</div>
                                <div
                                    className="content-bottom-hint"
                                    onClick={() => {
                                        window.location.href = `${window.location.origin}/blog/points`;
                                    }}
                                >
                                    了解貢獻值
                                    <img src={process.env.PUBLIC_URL + "/img/icon-chevron-right.svg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="use-app-start-btn">
                            <Button isShowIcon={false} onclickFn={() => ChangeURL("/login")} btnName="立即開始" />
                        </div>
                    </div>
                    <div className="content-title-img-desktop">
                        <img src={process.env.PUBLIC_URL + "/img/pic-userbenifit.png"} alt="" />
                    </div>
                </div>
            </div>
            <div className="mission-block" onClick={() => {}}>
                <img
                    src={sBanner.img}
                    onClick={() => {
                        window.location.href = sBanner.url;
                    }}
                    alt=""
                />
            </div>
            <div className="homepage-contact">
                <div className="contact-block">
                    <div className="content-block">
                        <div className="content-hint">擁有 P2P 平台？</div>
                        <h1 className="title">登錄平台取得認證</h1>
                        <img
                            className="cooperation-img-mobile"
                            src={process.env.PUBLIC_URL + "/img/pic-cooperation.png"}
                            alt=""
                        />
                        <div className="content-text">
                            希望提高您網站的能見度？快速接觸投資者？或是發現新的合作夥伴和管道？攜手
                            P2PRank，讓您公開的流量排名能真正反映您在現實世界中的成功。讓您的網站取得認證，提升您的線上聲譽。
                        </div>
                        <div className="homepage-contact-btn">
                            <Button isShowIcon={false} btnName="聯絡我們" onclickFn={() => ChangeURL("/contact")} />
                        </div>
                        {/* <div className="content-bottom-hint center-hint">
                        了解登錄規範
                        <img src={process.env.PUBLIC_URL + "/img/icon-chevron-right.svg"} alt="" />
                    </div> */}
                    </div>
                    <div className="cooperation-img-desktop">
                        <img src={process.env.PUBLIC_URL + "/img/pic-cooperation.png"} alt="" />
                    </div>
                </div>
            </div>
            <Marquees />
        </div>
    );
};

export default HomePage;
