import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import ArticleScroll from '../components/Blog/ArticleScroll'
import Button from '../components/Button'
import CommentCard from '../components/Score/CommentCard'
import CompanyInfo from '../components/ScorePage/CompanyInfo'
import BaseContext from '../context/BaseContext'
import { getUintPlatform, platform_unit, postUserComment } from '../util/api'
import { FilterTab_Rank } from './Rank'
import Login from '../pages/Login'
import ScoreModal from '../components/ScorePage/ScoreModal'
import { VerifyAlert } from '../util/dataProcess'
import { useParams } from 'react-router-dom'
import Monitor from '../components/Score/Monitor'
import ReportPlatform from '../components/ScorePage/ReportPlatform'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

export interface iCommentCard {
    user_name: string
    comment: string
    created_at: string
    time_text: string
}

export interface recommendScore {
    score1: number
    score2: number
    score3: number
    score4: number
    score5: number
    score6: number
    score7: number
    score8: number
    comment: string
    [x: string]: any
}

const initRecommendScore: recommendScore = {
    score1: 0,
    score2: 0,
    score3: 0,
    score4: 0,
    score5: 0,
    score6: 0,
    score7: 0,
    score8: 0,
    comment: '',
}

const initUnitPlat = {
    success: false,
    platform: {
        platform_code: '',
        platform_name: '',
        company_name: '',
        established_year: 0,
        hq_address: '',
        employee_count: 0,
        realtime_point: 0,
        monitor_status: 0,
        image: '',
        url: '',
        investment_type: '',
        score: {
            score1: 0,
            score2: 0,
            score3: 0,
            score4: 0,
            score5: 0,
            score6: 0,
            score7: 0,
            score8: 0,
            total: 0,
        },
        rank: 0,
    },
    can_comment: false,
    comments: [],
    articles: [],
}

const ScorePage = () => {
    const { platform_code } = useParams()

    const { bModal, setAlert, sIdToken, beLogin, ChangeURL, sBanner } = useContext(BaseContext)
    //文章顯示數量，初始為3個
    const [iMaxCommentNum, setMaxCommentNum] = useState<number>(3)
    //Score Modal顯示進度條
    const [bShowProgressBar, setShowProgressBar] = useState<boolean>(false)

    const [bShowCompany, setShowCompany] = useState<boolean>(false)

    const [unitPlatform, setunitPlatform] = useState<platform_unit>(initUnitPlat)
    const [unitRecommendScore, setUnitScore] = useState<recommendScore>(initRecommendScore)

    const [size, setSize] = useState(0)

    const wrapperRef = useRef<any>(null)

    const closeWriteScore = () => {
        /*clear select score when close score block*/
        setUnitScore(initRecommendScore)
    }

    const getUnitData = (platformCode: string) => {
        getUintPlatform(platformCode).then((res) => {
            if (res.success) {
                setunitPlatform(res)
                document.title = res.platform.platform_name + ' - 平台資訊與使用評價｜P2PRank'
            }
        })
    }

    const submitScore = () => {
        if (checkScoreInput() !== 8) {
            VerifyAlert({
                title: '送出失敗！',
                content: `請完整填寫八個評分`,
                btnText: '返回',
                MsgType: 2,
                btnOnclick: () => {
                    setAlert('Msg', false)
                },
                setAlert: setAlert,
            })
        } else {
            closeWriteScore()
            setShowProgressBar(false)
            setAlert('Modal', false)
            if (platform_code)
                postUserComment(sIdToken.current, platform_code, unitRecommendScore).then((res) => {
                    if (res.success) {
                        VerifyAlert({
                            title: '評分完成！',
                            content: '感謝您為平台評分，為這個社群貢獻一己之力。',
                            btnText: '領取貢獻值',
                            MsgType: 1,
                            btnOnclick: () => {
                                setAlert('Msg', false)
                                getUnitData(platform_code)
                            },
                            setAlert: setAlert,
                        })
                    } else {
                        VerifyAlert({
                            title: '評分失敗',
                            content: `${res.msg}`,
                            btnText: '返回',
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert('Msg', false)
                            },
                            setAlert: setAlert,
                        })
                    }
                })
        }
    }

    const giveScore = () => {
        if (sIdToken.current !== '' && beLogin.current) {
            showModal()
            setShowProgressBar(true)
        } else {
            LoginModal()
        }
    }

    const showModal = () => {
        setAlert(
            'Modal',
            true,
            <ScoreModal
                unitRecommendScore={unitRecommendScore}
                setUnitScore={setUnitScore}
                submitScore={submitScore}
            />,
            `為 ${unitPlatform.platform.platform_name} 評分`,
            0,
            true,
            false
        )
    }

    const LoginModal = () => {
        setAlert(
            'Modal',
            true,
            <div id="modal-login">
                <Login />
            </div>,
            '',
            0,
            true,
            false
        )
    }

    const ReportPlatformModal = () => {
        if (sIdToken.current !== '' && beLogin.current) {
            setAlert(
                'Modal',
                true,
                <ReportPlatform platform_code={platform_code ? platform_code : ''} />,
                '違約案件舉報',
                0,
                true,
                false
            )
        } else {
            LoginModal()
        }
    }

    const showMoreComent = () => {
        unitPlatform.comments.length - iMaxCommentNum > 3
            ? setMaxCommentNum(iMaxCommentNum + 3)
            : setMaxCommentNum(unitPlatform.comments.length)
    }

    function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowCompany(false)
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside)
        return () => {
            document.body.removeEventListener('click', handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef])

    useEffect(() => {
        if (bModal) showModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitRecommendScore])

    const checkScoreInput = () => {
        //計算填答了幾筆分數
        const keys = Object.keys(unitRecommendScore)
        let count = 0
        keys.forEach((key: string) => {
            if (key !== 'comment' && unitRecommendScore[key] > 0) {
                count++
            }
        })
        return count
    }

    const checkUserCommnent = (
        comments: {
            user_name: string
            comment: string
            created_at: string
            time_text: string
        }[]
    ) => {
        let check: boolean = false
        comments.forEach((comment) => {
            if (comment.comment !== null) {
                check = true
                return
            }
        })
        return check
    }

    useEffect(() => {
        if (!bModal) {
            setShowProgressBar(false)
            closeWriteScore()
        }
    }, [bModal])

    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth)
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    useEffect(() => {
        setShowCompany(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size])

    useEffect(() => {
        if (platform_code) getUnitData(platform_code)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="ScorePage">
            <div className="title-block">
                <Monitor
                    monitorStatus={unitPlatform.platform.monitor_status}
                    ReportPlatformModal={ReportPlatformModal}
                />
                <div className="back-image">
                    <div className="back-image-blue"></div>
                    <div className="back-image-company">
                        <img src={unitPlatform.platform.image} alt="" />
                    </div>
                </div>
                <div className="title-block-monitor">
                    <ol className="breadcrumb">
                        <li className="first-li" onClick={() => ChangeURL('/rankpage')}>
                            <span className="breadcrumb-item">平台排名總覽</span>
                        </li>
                        <img src={process.env.PUBLIC_URL + '/img/icon-bread-arrow.svg'} alt="" />
                        <li className="breadcrumb-item-not-active">{unitPlatform.platform.platform_name}</li>
                    </ol>
                </div>
                <div className="title-block-platformname">
                    <div className="platform-name">
                        {unitPlatform.platform.platform_name}
                        <div className="mobile-company">
                            <Tippy
                                zIndex={997}
                                visible={bShowCompany}
                                animation="scale"
                                placement="bottom"
                                theme={'light'}
                                appendTo={'parent'}
                                maxWidth={'250px'}
                                content={
                                    <CompanyInfo
                                        companyName={unitPlatform.platform.company_name}
                                        year={unitPlatform.platform.established_year}
                                        investment_type={
                                            unitPlatform.platform.investment_type !== undefined ||
                                            unitPlatform.platform.investment_type !== null
                                                ? unitPlatform.platform.investment_type
                                                : ''
                                        }
                                        location={unitPlatform.platform.hq_address}
                                    />
                                }
                            >
                                <img
                                    ref={wrapperRef}
                                    onClick={() => setShowCompany(!bShowCompany)}
                                    src={process.env.PUBLIC_URL + '/img/icon-info-circle.svg'}
                                    alt=""
                                />
                            </Tippy>
                        </div>
                    </div>
                    <p onClick={ReportPlatformModal}>舉報違約</p>
                    <div className="plat-all-info">
                        <div className="desktop-company">
                            <CompanyInfo
                                companyName={unitPlatform.platform.company_name}
                                year={unitPlatform.platform.established_year}
                                investment_type={
                                    unitPlatform.platform.investment_type !== undefined ||
                                    unitPlatform.platform.investment_type !== null
                                        ? unitPlatform.platform.investment_type
                                        : ''
                                }
                                location={unitPlatform.platform.hq_address}
                            />
                        </div>
                        <div className="plat-score-rank-block">
                            <div className="title-block-score">
                                <p className="title-block-subtitle">綜合評分</p>
                                <div className="platform-score">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/img/icon-star-black.svg'} alt="" />
                                        <span>{unitPlatform.platform.score.total}</span>
                                    </div>
                                    <div>
                                        <span>({unitPlatform.comments.length})</span>
                                    </div>
                                </div>
                            </div>
                            <div className="title-block-rank">
                                <p className="title-block-subtitle">當前排名</p>
                                <div>
                                    <span>#</span>
                                    <span>{unitPlatform.platform.rank}</span>
                                </div>
                            </div>
                            <div className="platURL">
                                <a href={unitPlatform.platform.url} target="_blank">
                                    前往平台
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="underline">
                    <div></div>
                </div>
            </div>
            <div className="score-block">
                <div className="score-table">
                    <div className="title">使用評價</div>
                    <div className="row">
                        {FilterTab_Rank.slice(1, FilterTab_Rank.length).map(
                            (item: { button_text: string; Type: number }) => {
                                return (
                                    <div className="row-cell">
                                        <CircularProgressbar
                                            className="circle-bar"
                                            value={unitPlatform.platform.score['score' + item.Type]}
                                            maxValue={5}
                                            text={`${unitPlatform.platform.score['score' + item.Type]}`}
                                            strokeWidth={3}
                                            styles={buildStyles({
                                                textColor: `${
                                                    unitPlatform.platform.score['score' + item.Type] >= 3
                                                        ? '#00278D'
                                                        : '#1A55C3'
                                                }`,
                                                pathColor: `${
                                                    unitPlatform.platform.score['score' + item.Type] >= 3
                                                        ? '#00278D'
                                                        : '#1A55C3'
                                                }`,
                                            })}
                                        />
                                        <div className="row-title">{item.button_text}</div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
                <div className="score-comment-btn">
                    <Button isShowIcon={false} onclickFn={giveScore} btnName="立即評分" />
                </div>
                <div
                    className="score-hint"
                    onClick={() => (window.location.href = `${window.location.origin}/blog/scoring`)}
                >
                    了解評分機制
                    <img src={process.env.PUBLIC_URL + '/img/icon-chevron-right.svg'} alt="" />
                </div>
                <div className="comment-block">
                    <div className="title">用戶評論</div>
                    {checkUserCommnent(unitPlatform.comments) ? (
                        <>
                            <div className="user-comments">
                                {unitPlatform.comments.slice(0, iMaxCommentNum).map((CommentItem: iCommentCard) => {
                                    return (
                                        <CommentCard
                                            UsrComment={CommentItem}
                                            key={CommentItem.user_name + CommentItem.created_at.split(' ')[0]}
                                        />
                                    )
                                })}
                            </div>
                            {unitPlatform.comments.length > iMaxCommentNum && (
                                <div className="more-user-comment" onClick={showMoreComent}>
                                    <span>載入更多評論({unitPlatform.comments.length - iMaxCommentNum})</span>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-comment">目前尚無評論</div>
                    )}
                </div>
            </div>
            <div className="article-block">
                <div className="title">平台相關消息</div>
                <ArticleScroll vArticleData={unitPlatform.articles} ArticleScrollIndex={1} />
            </div>
            <div className="risk-img">
                <img
                    src={sBanner.img}
                    onClick={() => {
                        window.location.href = sBanner.url
                    }}
                    alt=""
                />
            </div>
            <div className="report-block">
                <img className="report-block-img" src={process.env.PUBLIC_URL + '/img/search-wrong.png'} alt="" />
                <div className="report-block-text">
                    <div className="report-block-title">平台發生違約案件？</div>
                    <div className="report-block-info">回報平台違約案件，經查證屬實可獲得貢獻值獎勵。</div>
                    <div className="report-block-hint" onClick={ReportPlatformModal}>
                        舉報違約案件
                        <img src={process.env.PUBLIC_URL + '/img/icon-chevron-right.svg'} alt="" />
                    </div>
                </div>
            </div>
            {bShowProgressBar && (
                <div className="ScoreModalProgress">
                    <div className="ScoreModalProgress-block">
                        <div className="ScoreModalProgress-item">
                            {Array.from(Array(8).keys()).map((number: number) => {
                                return <div className={`${number < checkScoreInput() ? 'active' : ''}`}></div>
                            })}
                        </div>
                        <p>完成評分以獲得貢獻值（ {checkScoreInput()} / 8 ）</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/img/score-coin.png'} alt="" />
                </div>
            )}
        </div>
    )
}

export default ScorePage
