import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Article from '../components/Blog/Article'
import SearchBar from '../components/controls/SearchBar'
import PlatCard from '../components/Plat/PlatCard'
import BaseContext from '../context/BaseContext'
import { platform, postSearch, searchOutput } from '../util/api'
import { iArticle } from './BlogPage'

const SearchPage = () => {
    const { searchWord } = useParams()
    const { ChangeURL } = useContext(BaseContext)

    const [vSearchList, setvSearchList] = useState<searchOutput>({
        success: false,
        articles: [],
        platforms: [],
    })

    const getSearchData = () => {
        if (searchWord)
            postSearch({ search: searchWord }).then((res) => {
                if (res.success) setvSearchList(res)
            })
    }
    useEffect(() => {
        getSearchData()
        document.title = searchWord + '的搜尋結果｜P2PRank'
    }, [searchWord])

    return (
        <>
            <SearchBar />
            <div id="SearchPage">
                <div className="title-block">
                    <ol className="breadcrumb">
                        <li className="first-li" onClick={() => ChangeURL('/')}>
                            <span className="breadcrumb-item">首頁</span>
                        </li>
                        <img src={process.env.PUBLIC_URL + '/img/icon-bread-arrow.svg'} alt="" />
                        <li className="breadcrumb-item-not-active">“{searchWord}” 的搜尋結果</li>
                    </ol>
                </div>
                <div className="search-block">
                    {vSearchList.articles.length === 0 && vSearchList.platforms.length === 0 ? (
                        <div className="search-block-none">沒有相關內容</div>
                    ) : (
                        <>
                            <div className="search-block-title">“{searchWord}” 的搜尋結果</div>
                            {vSearchList.platforms.length !== 0 && (
                                <div className="platforms-list">
                                    {vSearchList.platforms.map((item: platform, index) => {
                                        return <PlatCard key={index} plat={item} />
                                    })}
                                </div>
                            )}
                            {vSearchList.articles.length !== 0 && (
                                <div className="article-list">
                                    {vSearchList.articles.map((item: iArticle, index) => {
                                        return <Article key={index} Article={item} />
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default SearchPage
