import { Fragment, useContext, useEffect, useState } from "react";
import BaseContext from "../context/BaseContext";
import { getMarquees } from "../util/api";

const Marquees = () => {
    const [bShowMarquees, setShowMarquees] = useState<boolean>(false);

    const [sMarquees, setMarquees] = useState<string>("");

    const { errorProcess } = useContext(BaseContext);

    const getMarqueesAPI = () => {
        getMarquees()
            .then(
                (res: {
                    success: boolean;
                    marquees: {
                        marquee_id: number;
                        content: string;
                        order: number;
                    }[];
                }) => {
                    if (res.success) {
                        setMarquees(res.marquees[0].content);
                        if (res.marquees[0].content !== "" || res.marquees[0].content !== null) {
                            setShowMarquees(true);
                        }
                    }
                }
            )
            .catch((error: any) => {
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };
    useEffect(() => {
        getMarqueesAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {bShowMarquees && (
                <div id="Marquees">
                    <div className="marquees-block">
                        <img
                            className="close-icon"
                            onClick={() => setShowMarquees(false)}
                            src={process.env.PUBLIC_URL + "/img/icon-close-white.svg"}
                            alt=""
                        />
                        <div className="marquees-content" dangerouslySetInnerHTML={{ __html: sMarquees }}></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Marquees;
