import { Rating } from "@mui/material";
import React from "react";
import { recommendScore } from "../../pages/ScorePage";
import StarIcon from "@mui/icons-material/Star";

interface props {
    iBarValue: number;
    values: recommendScore;
    setBarValue: React.Dispatch<React.SetStateAction<recommendScore>>;
    Name: string;
}

const SliderStar = ({ iBarValue, values, setBarValue, Name }: props) => {
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setBarValue({
            ...values,
            [name]: parseInt(value),
        });
    };
    return (
        <Rating
            name={Name}
            defaultValue={0}
            value={iBarValue}
            onChange={handleChange}
            precision={1}
            size="large"
            icon={<img src={process.env.PUBLIC_URL + "/img/icon-star-fill.svg"} alt="" />}
            emptyIcon={<img src={process.env.PUBLIC_URL + "/img/icon-star.svg"} alt="" />}
            sx={{
                fontSize: "2.5rem",
                margin: "0 2rem",
                color: "#1A55C3",
            }}
        />
    );
};

export default SliderStar;
