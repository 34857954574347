import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BaseContext from "../../context/BaseContext";
import {
    PhoneVerification,
    PhoneVerificationCheck,
    PhoneVerificationForgerCheck,
    PhoneVerificationForget,
} from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";
import Button from "../Button";
import Input from "../controls/Input";

export interface InputCodeNumber {
    "number-1": string;
    "number-2": string;
    "number-3": string;
    "number-4": string;
    "number-5": string;
    "number-6": string;
    [x: string]: string;
}

const initCodeNumber = {
    "number-1": "",
    "number-2": "",
    "number-3": "",
    "number-4": "",
    "number-5": "",
    "number-6": "",
};

interface props {
    successFn: () => void;
    sUserPhone: string;
    setUserPhone: React.Dispatch<React.SetStateAction<string>>;
    sPhoneVerify: InputCodeNumber;
    setPhoneVerify: React.Dispatch<React.SetStateAction<InputCodeNumber>>;
}

const PhoneVerify = ({ sUserPhone, setUserPhone, successFn, sPhoneVerify, setPhoneVerify }: props) => {
    const location = useLocation();
    // context
    const { ChangeURL, sIdToken, setAlert, errorProcess } = useContext(BaseContext);

    const [sUserPhoneClick, setUserPhoneClick] = useState<boolean>(false);
    const [sShowVerify, setShowVerify] = useState<boolean>(false);
    const [sUserPhoneSend, setUserPhoneSend] = useState<string>("");
    const [sUserPhoneError, setUserPhoneError] = useState<string>("");
    const [iTimer, setiTimer] = useState<number>(0);

    const handleInputChange = (e: any) => {
        setUserPhone(e.target.value);
    };

    const validate = () => {
        if (sUserPhone === "") {
            setUserPhoneError("此欄位必填");
            return false;
        } else if (
            !(
                (sUserPhone.length === 9 && sUserPhone.split("")[0] === "9") ||
                (sUserPhone.length === 10 && sUserPhone.split("")[0] + sUserPhone.split("")[1] === "09")
            )
        ) {
            setUserPhoneError("請輸入有效電話號碼");
            return false;
        }
        setUserPhoneError("");
        return true;
    };

    const getphoneVerifyNumber = () => {
        let postPhone = sUserPhone.split("")[0] === "9" ? "0" + sUserPhone : sUserPhone;
        if (validate()) {
            setUserPhoneClick(true);
            location.pathname.indexOf("forgetstepone") > -1
                ? PhoneVerificationForget({ phone: postPhone })
                      .then((res: any) => {
                          if (res.success) {
                              setShowVerify(true);
                              setUserPhoneSend(sUserPhone);
                              setiTimer(50);
                          } else {
                              VerifyAlert({
                                  title: "無法領取驗證碼",
                                  content: res.msg,
                                  btnText: "返回",
                                  MsgType: 2,
                                  btnOnclick: () => {
                                      setUserPhoneClick(false);
                                      setAlert("Msg", false);
                                  },
                                  setAlert: setAlert,
                              });
                          }
                      })
                      .catch((error: any) => {
                          console.error(error);
                          let error_status: number;
                          if (error.response !== undefined) {
                              error_status = error.response.status;
                          } else {
                              error_status = 0;
                          }
                          errorProcess(error_status, error);
                      })
                : PhoneVerification(sIdToken.current, { phone: postPhone })
                      .then((res: any) => {
                          if (res.success) {
                              setShowVerify(true);
                              setUserPhoneSend(sUserPhone);
                              setiTimer(50);
                          } else {
                              VerifyAlert({
                                  title: "無法領取驗證碼",
                                  content: res.msg,
                                  btnText: "返回",
                                  MsgType: 2,
                                  btnOnclick: () => {
                                      setUserPhoneClick(false);
                                      setAlert("Msg", false);
                                  },
                                  setAlert: setAlert,
                              });
                          }
                      })
                      .catch((error: any) => {
                          console.error(error);
                          let error_status: number;
                          if (error.response !== undefined) {
                              error_status = error.response.status;
                          } else {
                              error_status = 0;
                          }
                          errorProcess(error_status, error);
                      });
        }
    };

    const countdown = () => {
        return setTimeout(() => {
            if (iTimer === 0) {
                setUserPhoneClick(false);
            } else if (iTimer > 0) {
                setiTimer((previTimer) => previTimer - 1);
            }
        }, 1000);
    };

    const handleChange = (e: any) => {
        const { maxLength, value, name } = e.target;
        const fieldIndex = name.split("-")[1];

        // Check if they hit the max character length
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 7) {
                // Get the next input field
                const nextSibling = document.querySelector(`input[name=number-${parseInt(fieldIndex, 10) + 1}]`);
                (nextSibling as HTMLElement)?.focus();
            }
        }

        setPhoneVerify({
            ...sPhoneVerify,
            [name]: value,
        });
    };

    const submitVerifyCode = () => {
        const tempString = Object.values(sPhoneVerify).join("");
        if (location.pathname.indexOf("forgetstepone") > -1) {
            PhoneVerificationForgerCheck({ phone: sUserPhone, code: tempString })
                .then((res: any) => {
                    if (res.success) {
                        successFn();
                    } else {
                        VerifyAlert({
                            title: "驗證失敗",
                            content: "請確認您的驗證碼是否輸入正確。",
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                        setPhoneVerify(initCodeNumber);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        } else {
            if (sIdToken.current === "") {
                alert("token isn't valid");
                ChangeURL("/login");
            }

            PhoneVerificationCheck(sIdToken.current, { code: tempString })
                .then((res: any) => {
                    if (res.success) {
                        successFn();
                    } else {
                        VerifyAlert({
                            title: "驗證失敗",
                            content: "請確認您驗證碼是否輸入正確。",
                            btnText: "返回",
                            MsgType: 2,
                            btnOnclick: () => {
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                        setPhoneVerify(initCodeNumber);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    useEffect(() => {
        let timeoutID = countdown();
        return () => {
            window.clearTimeout(timeoutID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iTimer]);
    return (
        <>
            <div id="PhoneVerify" className="input-invite-block">
                <div>
                    <Input
                        name="inviteCode"
                        label="行動電話"
                        value={sUserPhone}
                        onChange={(e) => handleInputChange(e)}
                        error={sUserPhoneError}
                        type={"number"}
                        hasPlaceholder={false}
                        inputdisable={sUserPhoneClick}
                    />
                    <button
                        disabled={sUserPhoneClick}
                        onClick={getphoneVerifyNumber}
                        className={`get-message ${sUserPhoneClick && "get-message-click"}`}
                    >
                        獲取簡訊驗證碼
                    </button>
                </div>
            </div>
            {sShowVerify && (
                <div className="input-phone-check">
                    <p>已發送簡訊至 +886{sUserPhoneSend} </p>
                    <div>
                        {Object.keys(sPhoneVerify).map((item: string) => {
                            return (
                                <input
                                    key={item}
                                    type="text"
                                    maxLength={1}
                                    pattern="([12345])\w{0}"
                                    value={sPhoneVerify[item]}
                                    name={item}
                                    onChange={handleChange}
                                />
                            );
                        })}
                    </div>
                    <p className="hint">
                        {iTimer} 秒後可 <span>再次索取驗證碼</span>
                    </p>
                </div>
            )}
            <div className="submit">
                <Button isShowIcon={false} disable={!sUserPhoneClick} btnName="驗證" onclickFn={submitVerifyCode} />
            </div>
        </>
    );
};

export default PhoneVerify;
