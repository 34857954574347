import Button from "../components/Button";

interface verifySuccess {
    title: string;
    content: string;
    btnText: string;
    MsgType: number;
    btnOnclick: () => void;
    setAlert: (
        type: string,
        bAlert: boolean,
        sAlert?: JSX.Element,
        sAlertTitle?: string,
        iMsgType?: number,
        bCloseIcon?: boolean
    ) => void;
}

//用於顯示成功訊息 (邀請碼成功、驗證手機成功) MsgType 1 :成功 2 :失敗
export const VerifyAlert = ({ title, content, btnText, btnOnclick, setAlert, MsgType }: verifySuccess) => {
    //need post different api and different modal
    const confirm_element = (
        <div id="VerifyAlert">
            <div className="title">{title}</div>
            <div className="text-content">{content}</div>
            <div className="msg-btn">
                <Button isShowIcon={false} onclickFn={btnOnclick} btnName={btnText} />
            </div>
        </div>
    );
    setAlert("Msg", true, confirm_element, "", MsgType, false);
};
