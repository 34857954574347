import React, { useContext } from "react";
import BaseContext from "../../context/BaseContext";
import { filter } from "../../pages/BlogPage";
import { FilterTab_Rank } from "../../pages/Rank";
import { recommendScore } from "../../pages/ScorePage";
import Button from "../Button";
import SliderStar from "../Score/SliderStar";

interface props {
    unitRecommendScore: recommendScore;
    setUnitScore: React.Dispatch<React.SetStateAction<recommendScore>>;
    submitScore: () => void;
}

const ScoreModal = ({ unitRecommendScore, setUnitScore, submitScore }: props) => {
    const width = window.screen.width;
    const isMobile = !(width > 960);

    const returnLabelOfButton = (button_text: string) => {
        switch (button_text) {
            case "報酬率":
                return "投資獲利能力";
            case "真實性":
                return "資訊透明程度";
            case "風險控制":
                return "案件違約控制";
            case "抵押擔保":
                return "擔保品涵蓋程度";
            case "違約催收":
                return "法務催收能力";
            case "回收速度":
                return "債權時間長短";
            case "案件數量":
                return "案件數量";
            case "活躍度":
                return "平台露出頻率";
            default:
                return "";
        }
    };

    const returnScoreMean = (score: number) => {
        switch (score) {
            case 0:
                return "我認為...";
            case 1:
                return "非常低";
            case 2:
                return "很低";
            case 3:
                return "普通";
            case 4:
                return "很高";
            case 5:
                return "非常高";
            default:
                return "我認為...";
        }
    };

    const handleTextArea = (e: any) => {
        setUnitScore({ ...unitRecommendScore, comment: e.target.value });
    };

    return (
        <>
            <div id="slider-score" className={!isMobile ? "scrollbarCol" : ""}>
                <div className="slider-block">
                    {FilterTab_Rank.slice(1, 9).map((item: filter, index) => {
                        return (
                            <div className="slider-bar" key={index}>
                                <label className="slider-bar-title">{returnLabelOfButton(item.button_text)}</label>
                                <label className="slider-bar-mean">
                                    {returnScoreMean(unitRecommendScore["score" + item.Type])}
                                </label>
                                <SliderStar
                                    values={unitRecommendScore}
                                    iBarValue={unitRecommendScore["score" + item.Type]}
                                    setBarValue={setUnitScore}
                                    Name={`${"score" + item.Type}`}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="slider-score-title">對於這個平台，您有什麼想法？</div>
                <textarea
                    className="input-area"
                    placeholder="告訴大家您的經驗"
                    value={unitRecommendScore.comment}
                    onChange={(e) => handleTextArea(e)}
                />
                <div className="slider-score-btn">
                    <Button
                        isShowIcon={false}
                        onclickFn={() => {
                            submitScore();
                        }}
                        btnName="送出"
                    />
                </div>
                <p className="slider-score-submit-hint">送出後不可修改，請確認後再送出！</p>
            </div>
        </>
    );
};

export default ScoreModal;
