import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import BaseContext from "../../context/BaseContext";
import { withdrawApply } from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";

const WithdrawPage = () => {
    const { logoutNative, sIdToken, updateUserInfo, ChangeURL, setAlert, vUserInfo, errorProcess } =
        useContext(BaseContext);

    const [iUserCount, setUserCount] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);

    const handleChange = (e: any) => {
        setUserCount(e.target.value);
    };

    const replaceBankAccount = () => {
        if (vUserInfo.bank_account_number !== null)
            return `${"*********"}${vUserInfo.bank_account_number?.slice(10, 14)} `;
        return "";
    };

    const translateCount = () => {
        return Math.floor(iUserCount / 10);
    };

    const handleSubmit = () => {
        if (iUserCount < 1000) {
            setError(true);
        } else {
            setError(false);
            withdrawApply(sIdToken.current, { point: translateCount() * 10 })
                .then((res) => {
                    if (res.success) {
                        VerifyAlert({
                            title: "已提交申請",
                            content: "提交後需等待約 2-3 個工作天進行審核並發送至您的帳戶",
                            btnText: "返回個人頁",
                            MsgType: 1,
                            btnOnclick: () => {
                                ChangeURL("/user");
                                updateUserInfo(sIdToken.current);
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    } else {
                        if (!res.done) {
                            VerifyAlert({
                                title: "提交失敗",
                                content: `${res.msg}`,
                                btnText: "請重新登入",
                                MsgType: 2,
                                btnOnclick: () => {
                                    logoutNative();
                                    ChangeURL("/login");
                                    setAlert("Msg", false);
                                },
                                setAlert: setAlert,
                            });
                        } else {
                            VerifyAlert({
                                title: "提交失敗",
                                content: `${res.msg}`,
                                btnText: "返回",
                                MsgType: 2,
                                btnOnclick: () => {
                                    setAlert("Msg", false);
                                },
                                setAlert: setAlert,
                            });
                        }
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    useEffect(() => {
        document.title = "提領申請｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="WithdrawPage">
            <div className="withdraw-block">
                <div className="title">提領申請</div>
                <div className="bank-info-block">
                    <p className="label">提領帳號</p>
                    <p className="input-block">
                        {vUserInfo.bank_account_name !== null ? vUserInfo.bank_account_name : ""} {replaceBankAccount()}
                    </p>
                    <div className="bank-setting-label">
                        <p
                            onClick={() => {
                                ChangeURL("/banksetting");
                            }}
                        >
                            銀行帳號設定
                        </p>
                    </div>
                    <p className="label">提領點數</p>
                    <div className="input-block input-margin">
                        <input type="number" onChange={(e) => handleChange(e)} placeholder="最低 1000 pts" />
                    </div>
                    {error && (
                        <p className="warning_word">
                            <img src={process.env.PUBLIC_URL + "/img/icon-input-error.svg"} alt="" />
                            請輸入最少1000pts，才可提領
                        </p>
                    )}
                    <p className="label">轉換後金額</p>
                    <div className="input-block input-margin">{translateCount()}</div>
                    <p className="label">手續費</p>
                    <p className="hadding">15.0 TWD</p>
                </div>
                <div className="user-money-block">
                    <p>實收金額</p>
                    <div className="money">
                        <span>{Math.floor(iUserCount / 10) - 15 > 0 ? Math.floor(iUserCount / 10) - 15 : 0}</span>TWD
                    </div>
                </div>
                <div className="withdraw-btn">
                    <Button isShowIcon={false} onclickFn={handleSubmit} btnName={"送出"} />
                </div>
                <div
                    className="cancel"
                    onClick={() => {
                        ChangeURL("/user");
                    }}
                >
                    取消
                </div>
            </div>
        </div>
    );
};

export default WithdrawPage;
