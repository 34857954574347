import { useContext } from 'react'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import BaseContext from '../context/BaseContext'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

enum MsgType {
    Loading = 0,
    Success = 1,
    Fail = 2,
    Warning = 3,
    Login = 4,
}

// iMsgType === 0 : Loading
function Message() {
    // modal element
    const elMsg = document.getElementById('msg')

    // context
    const { bMsg, sMsg, sMsgTitle, iMsgType = true } = useContext(BaseContext)

    // const clickBGtoClose = () => {
    //     if (iMsgType === 0) return;
    //     setbMsg(false);
    // };

    // const closeSuccessMsgAuto = (Time: number) => {
    //     const millisecond = Time * 1000;
    //     return window.setTimeout(() => {
    //         setbMsg(false);
    //     }, millisecond);
    // };

    // useEffect(() => {
    //     if (!bMsg || (iMsgType !== MsgType.Success && iMsgType !== MsgType.Fail)) return;

    //     const iTimeoutID = closeSuccessMsgAuto(iMsgType === MsgType.Success ? 2 : 10);
    //     return () => {
    //         window.clearTimeout(iTimeoutID);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [bMsg]);

    /***
     * className
     * title -  使用於msg 第一個標題
     * text-content - 使用於 msg 內文設定
     * msg-btn -
     ***/

    const sEl = (
        <div
            className="gray-back"
            onClick={(e) => {
                e.stopPropagation()
                // clickBGtoClose();
            }}
        >
            <div className="msg-content" onClick={(e) => e.stopPropagation()}>
                {iMsgType !== MsgType.Loading && (
                    <div className="title-block">
                        <div className="title">{sMsgTitle}</div>
                    </div>
                )}
                <div className={`content ${iMsgType === MsgType.Success && 'success-img'}`}>
                    {iMsgType === MsgType.Loading ? (
                        <FontAwesomeIcon icon={faCircleNotch as IconProp} spin />
                    ) : iMsgType === MsgType.Success ? (
                        <img src={process.env.PUBLIC_URL + '/img/success.png'} alt="" />
                    ) : iMsgType === MsgType.Fail ? (
                        <img src={process.env.PUBLIC_URL + '/img/error.png'} alt="" />
                    ) : iMsgType === MsgType.Warning ? (
                        <FontAwesomeIcon icon={faExclamation as IconProp} />
                    ) : iMsgType === MsgType.Login ? (
                        <img src={process.env.PUBLIC_URL + '/img/login.png'} alt="" />
                    ) : (
                        <></>
                    )}
                    {iMsgType === MsgType.Loading ? <div>Loading</div> : sMsg}
                </div>
            </div>
        </div>
    )

    return <>{bMsg && (!elMsg ? null : createPortal(sEl, elMsg))}</>
}

export default Message
