import React from 'react'
import { usePagination } from './usePagination'

interface props {
    onPageChange: React.Dispatch<React.SetStateAction<number>>
    totalCount: number
    siblingCount: number
    currentPage: number
    pageSize: number
}

const Pagination = ({ onPageChange, totalCount, siblingCount, currentPage, pageSize }: props) => {
    const paginationRange = usePagination({
        totalCount: totalCount,
        pageSize: pageSize,
        siblingCount: siblingCount,
        currentPage: currentPage,
    })
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 /* || paginationRange.length < 2*/) {
        return null
    }

    // const onNext = () => {
    //     onPageChange(currentPage + 1);
    // };
    // const onPrevious = () => {
    //     onPageChange(currentPage - 1);
    // };

    // let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul className="pagination">
            {paginationRange.map((pageNumber, index: number) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === -1) {
                    return (
                        <li key={`${pageNumber}_${index}`} className="dots">
                            ...
                        </li>
                    )
                }
                // Render our Page Pills
                return (
                    <li
                        key={pageNumber}
                        aria-current={pageNumber === currentPage ? 'page' : 'false'}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                )
            })}
        </ul>
    )
}

export default Pagination
