import React, { useContext } from "react";
import BaseContext from "../context/BaseContext";

const Footer = () => {
    const { ChangeURL } = useContext(BaseContext);
    return (
        <footer id="Footer">
            <div className="footer-content">
                <div className="foot-logo">
                    <div className="logo-block">
                        <img src={process.env.PUBLIC_URL + "/img/footer-logo-p2prank.png"} alt="" />
                    </div>
                    {/* <div className="social-com">
                        <span>請關注我們：</span>
                        <img src={process.env.PUBLIC_URL + "/img/icon-fb-white.svg"} alt="" />
                        <img src={process.env.PUBLIC_URL + "/img/icon-ig-white.svg"} alt="" />
                    </div> */}
                </div>
                <div className="foot-info">
                    <div className="nav-item">
                        <div onClick={() => ChangeURL("/rankpage")}>平台排名</div>
                        <div onClick={() => ChangeURL("/blog")}>投資消息</div>
                        <div onClick={() => ChangeURL("/about")}>關於我們</div>
                        <div onClick={() => ChangeURL("/contact")}>聯絡我們</div>
                        <div className="privacy-com" onClick={() => ChangeURL("/privacypolicy")}>
                            隱私權政策
                        </div>
                        <div className="policy-com" onClick={() => ChangeURL("/termsandconditions")}>
                            服務條款
                        </div>
                    </div>
                    <p className="last-word-com">© {new Date().getFullYear()} P2PRank. All rights reserved.</p>

                    {/* <div className="social-icon-mobile">
                        <div>
                            <span>請關注我們：</span>
                            <img src={process.env.PUBLIC_URL + "/img/icon-fb-white.svg"} alt="" />
                            <img src={process.env.PUBLIC_URL + "/img/icon-ig-white.svg"} alt="" />
                        </div>
                    </div> */}
                    <div className="privacy-mobile">
                        <div onClick={() => ChangeURL("/privacypolicy")}>隱私權政策</div>
                        <div onClick={() => ChangeURL("/termsandconditions")}>服務條款</div>
                    </div>
                </div>
                <p className="last-word-mobile">© {new Date().getFullYear()} P2PRank. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
