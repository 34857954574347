import axios from "axios";
import { reportPlatform } from "../components/ScorePage/ReportPlatform";
import { iArticle } from "../pages/BlogPage";
import { recommendScore } from "../pages/ScorePage";
const REACT_APP_API_URL = "https://back.p2prank.tw/api";

export enum validation {
    notSuccessRegister = 0,
}
// get banner
export async function getMarquees(): Promise<{
    success: boolean;
    marquees: {
        marquee_id: number;
        content: string;
        order: number;
    }[];
}> {
    const res = await axios({
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/marquees`,
    });
    return res.data;
}

// get banner
export async function getBanner(): Promise<{
    success: boolean;
    banners: {
        image: string;
        mobile_image: string;
        text: string | null;
        link: string | null;
    }[];
}> {
    const res = await axios({
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/banners`,
    });
    return res.data;
}

// verify token
export async function verifyToken(token: string) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        method: "GET",
        url: `${REACT_APP_API_URL}/verify`,
    });
    return res.data;
}

// native login
export async function loginNativeUser(data: { email: string; password: string }) {
    const res = await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/login`,
        data: data,
    });
    return res.data;
}

// facebook login
export async function loginFaceBookUser(data: { email: string; name: string; id: string }) {
    const res = await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/facebook/login`,
        data: data,
    });
    return res.data;
}

// facebook login
export async function loginGoogleUser(data: { email: string; name: string; id: string }) {
    const res = await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/google/login`,
        data: data,
    });
    return res.data;
}

// user regist
export async function RegistAPI(data: { email: string; password: string; name: string }) {
    const res = await axios({
        method: "POST",
        url: `${REACT_APP_API_URL}/register`,
        data: data,
    });
    return res.data;
}

//user invite code
export async function InviteCodeAPI(token: string, data: { share_code: string }) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        method: "POST",
        url: `${REACT_APP_API_URL}/who/invite/me`,
        data: data,
    });
    return res.data;
}

// user get phone verify code
export async function PhoneVerification(token: string, data: { phone: string }) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        method: "POST",
        url: `${REACT_APP_API_URL}/request/verification/code`,
        data: data,
    });
    return res.data;
}

// user get phone verify code forget
export async function PhoneVerificationForget(data: { phone: string }) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        url: `${REACT_APP_API_URL}/request/forget/verification/code`,
        data: data,
    });
    return res.data;
}

//user phone code check
export async function PhoneVerificationCheck(token: string, data: { code: string }) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        method: "POST",
        url: `${REACT_APP_API_URL}/verify/verification/code`,
        data: data,
    });
    return res.data;
}

//user phone code check
export async function PhoneVerificationForgerCheck(data: { phone: string; code: string }) {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        url: `${REACT_APP_API_URL}/verify/forget/verification/code`,
        data: data,
    });
    return res.data;
}

//change name
export async function putPassword(data: { phone: string; code: string; password: string }) {
    const res = await axios({
        method: "PUT",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/update/password`,
        data: data,
    });
    return res.data;
}

//blog list
export async function getArticleList() {
    const res = await axios({
        method: "GET",
        url: `${REACT_APP_API_URL}/article/list`,
    });
    return res.data;
}

export interface articleDetail {
    success: boolean;
    article: {
        article_code: string;
        title: string;
        subtitle: string;
        tags: {
            value: string;
        }[];
        image: string;
        article_type: number;
        created_at: string;
        page_views: number;
        is_top: number;
        content: (
            | {
                  type: string;
                  text: string;
                  order: number;
              }
            | {
                  type: string;
                  text: string;
                  order: number;
              }
            | { type: string; order: number }
            | { type: string; image: string; alt: string; other: string; order: number }
            | { type: string; url: string; order: number }
            | { type: string; image: string; alt: string; other: string; order: number }
            | { type: string; article_code: string; order: number; title: string }
        )[];
        og_title: string;
        og_description: string;
        og_image: string;
    };
}

//blog detail
export async function getBlogDetailFromAPI(article_code: string) {
    const res = await axios({
        method: "GET",
        url: `${REACT_APP_API_URL}/article/${article_code}`,
    });
    return res.data;
}

//get user info
export async function getUserInfo(token: string) {
    const res = await axios({
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/user/info`,
    });
    return res.data;
}

//change name
export async function putUserName(token: string, data: { name: string }) {
    const res = await axios({
        method: "PUT",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/user/name`,
        data: data,
    });
    return res.data;
}

//change password
export async function putUserPassword(
    token: string,
    data: { old_password: string; new_password: string; new_password2: string }
) {
    const res = await axios({
        method: "PUT",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/user/password`,
        data: data,
    });
    return res.data;
}

//change BankInfo
export async function putBankInfo(
    token: string,
    data: {
        bank_code: string;
        bank_branch: string;
        bank_account_number: string;
        bank_account_name: string;
        // birth: string
    }
) {
    const res = await axios({
        method: "PUT",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/user/bank/info`,
        data: data,
    });
    return res.data;
}

//change password
export async function withdrawApply(
    token: string,
    data: {
        point: number;
    }
) {
    const res = await axios({
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/user/withdraw/apply`,
        data: data,
    });
    return res.data;
}

export interface platform {
    platform_code: string;
    platform_name: string;
    company_name: string;
    established_year: number;
    hq_address: string;
    employee_count: number;
    realtime_point: number;
    monitor_status: number;
    image: string;
    url: string;
    investment_type: string;
    score: {
        score1: number;
        score2: number;
        score3: number;
        score4: number;
        score5: number;
        score6: number;
        score7: number;
        score8: number;
        total: number;
        [x: string]: any;
    };
    rank: number;
}

export interface platform_all {
    success: boolean;
    platforms: platform[];
    updated_at: string;
}

export interface platform_unit {
    success: boolean;
    platform: platform;
    can_comment: boolean;
    comments: {
        user_name: string;
        comment: string;
        created_at: string;
        time_text: string;
    }[];
    articles: iArticle[];
}

//get all platforms
export async function getPlatforms(): Promise<platform_all> {
    const res = await axios({
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/platforms`,
    });
    return res.data;
}

export async function getUintPlatform(platform_code: string): Promise<platform_unit> {
    const res = await axios({
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/platform/${platform_code}`,
    });
    return res.data;
}

//post userScore
export async function postUserComment(token: string, platform_code: string, data: recommendScore) {
    const res = await axios({
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/platform/${platform_code}/comment`,
        data: data,
    });
    return res.data;
}

//post img
export async function postReportImage(token: string, platform_code: string, data: FormData) {
    const res = await axios({
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/platform/${platform_code}/response/image`,
        data: data,
    });
    return res.data;
}

//post report
export async function postReport(token: string, platform_code: string, data: reportPlatform) {
    const res = await axios({
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `${token}`,
        },
        url: `${REACT_APP_API_URL}/platform/${platform_code}/response`,
        data: data,
    });
    return res.data;
}

export interface searchOutput {
    success: boolean;
    articles: iArticle[];
    platforms: platform[];
}

export async function postSearch(data: { search: string }): Promise<searchOutput> {
    const res = await axios({
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        url: `${REACT_APP_API_URL}/search`,
        data: data,
    });
    return res.data;
}
