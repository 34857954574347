import { useContext, useEffect, useMemo, useState } from "react";
import BaseContext from "../../context/BaseContext";
import { iArticle } from "../../pages/BlogPage";
import Pagination from "../Pagination";
import ArticleListCard from "./ArticleListCard";

interface props {
    vArticleData: iArticle[];
    sClassName: number;
}

/*blog-2 page*/
const BlogArticle = ({ vArticleData, sClassName }: props) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    let PageSize = 15;

    const currentArticleData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        window.scrollTo(0, 0);
        return vArticleData.slice(firstPageIndex, lastPageIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [sClassName]);

    return (
        <>
            <div className="article-part">
                {currentArticleData.map((item: iArticle, index) => {
                    return <ArticleListCard key={index} Article={item} />;
                })}
            </div>
            <div className="pagination-block">
                <Pagination
                    currentPage={currentPage}
                    totalCount={vArticleData.length}
                    pageSize={PageSize}
                    siblingCount={1}
                    onPageChange={setCurrentPage}
                />
            </div>
        </>
    );
};

export default BlogArticle;
