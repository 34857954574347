import ArticleDetail from '../components/Blog/ArticleDetail'
import AboutUs from '../pages/AboutUs'
import BlogPage from '../pages/BlogPage'
import ContactUs from '../pages/ContactUs'
import HomePage from '../pages/HomePage'
import Login from '../pages/Login'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import RankPage from '../pages/Rank'
import Register from '../pages/Register'
import InputInvite from '../pages/Register/InputInvite'
import InputPhone from '../pages/Register/InputPhone'
import ScorePage from '../pages/ScorePage'
import SearchPage from '../pages/SearchPage'
import TermsAndConditions from '../pages/TermsAndConditions'
import UserPage from '../pages/User'
import BankSettingPage from '../pages/User/BankSettingPage'
import ForgetPhoneVerify from '../pages/User/ForgetPhoneVerify'
import ModifyPage from '../pages/User/ModifyPage'
import ResetPassword from '../pages/User/ResetPassword'
import WithdrawPage from '../pages/User/WithdrawPage'

const routes = [
    {
        path: '/',
        component: <HomePage />,
        breadcrumbName: '首頁',
    },
    {
        path: '/blog',
        component: <BlogPage />,
        breadcrumbName: 'Blog',
    },

    {
        path: '/blog/:id',
        component: <ArticleDetail />,
        breadcrumbName: 'ArticleDetail',
    },
    { path: '/login', component: <Login />, breadcrumbName: '會員登入' },
    {
        path: '/login/register',
        component: <Register />,
        breadcrumbName: '建立帳號',
        state: {
            name: '',
            email: '',
            id: '',
            from: '',
        },
    },
    {
        path: '/login/register/invite',
        component: <InputInvite />,
        breadcrumbName: '輸入邀請碼',
    },
    {
        path: '/login/invite',
        component: <InputInvite />,
        breadcrumbName: '輸入邀請碼',
    },
    {
        path: '/login/register/invite/phone',
        component: <InputPhone />,
        breadcrumbName: '驗證手機',
    },
    {
        path: '/login/invite/phone',
        component: <InputPhone />,
        breadcrumbName: '驗證手機',
    },
    {
        path: '/user',
        component: <UserPage />,
        breadcrumbName: '會員個人',
    },
    {
        path: '/usermodify',
        component: <ModifyPage />,
        breadcrumbName: '編輯會員',
    },
    {
        path: '/banksetting',
        component: <BankSettingPage />,
        breadcrumbName: '銀行帳戶',
    },
    {
        path: '/withdrawapply',
        component: <WithdrawPage />,
        breadcrumbName: '提領申請',
    },
    {
        path: '/rankpage',
        component: <RankPage />,
        breadcrumbName: '平台排名總覽',
    },
    {
        path: '/rankpage/:platform_code',
        component: <ScorePage />,
        breadcrumbName: '公司排名',
    },
    {
        path: '/contact',
        component: <ContactUs />,
        breadcrumbName: '聯絡我們',
    },
    {
        path: '/about',
        component: <AboutUs />,
        breadcrumbName: '關於我們',
    },
    {
        path: '/login/forgetstepone',
        component: <ForgetPhoneVerify />,
        breadcrumbName: '忘記密碼',
    },
    {
        path: '/login/forgetstepone/forgetsteptwo',
        component: <ResetPassword />,
        breadcrumbName: '重設密碼',
        state: {
            phone: '',
            code: '',
        },
    },
    {
        path: '/search/:searchWord',
        component: <SearchPage />,
        breadcrumbName: '搜尋頁面',
    },
    {
        path: '/termsandconditions',
        component: <TermsAndConditions />,
        breadcrumbName: '服務條款',
    },
    {
        path: '/privacypolicy',
        component: <PrivacyPolicy />,
        breadcrumbName: '隱私權政策',
    },
]

export default routes
