import React, { useContext, useEffect, useState } from "react";
import BankSetting from "../../components/UserPage/BankSetting";
import ModifyTab from "../../components/UserPage/ModifyTab";
import ModifyPassword from "../../components/UserPage/ModifyPassword";
import BaseContext from "../../context/BaseContext";
import { VerifyAlert } from "../../util/dataProcess";
import { useNavigate } from "react-router-dom";

export const enumModifyTab: { [x: string]: string } = {
    password: "修改密碼",
    bankAccount: "銀行帳戶資料",
};
const ModifyPage = () => {
    const navigate = useNavigate();
    const [sSelectedTab, setSelectedTab] = useState<string>(enumModifyTab.password);

    const { sIdToken, updateUserInfo, ChangeURL, setAlert, beLogin } = useContext(BaseContext);

    const bankSuccessFN = () => {
        VerifyAlert({
            title: "更新成功",
            content: "",
            btnText: "返回個人頁",
            MsgType: 1,
            btnOnclick: () => {
                updateUserInfo(sIdToken.current);
                ChangeURL("/user");
                setAlert("Msg", false);
            },
            setAlert: setAlert,
        });
    };

    const bankReturnFN = () => {
        ChangeURL("/user");
    };

    useEffect(() => {
        const localBeLogin = window.localStorage.getItem("beLogin");
        if (!localBeLogin) {
            navigate("/");
        }
        document.title = "帳號資料編輯｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="ModifyUser">
            <ModifyTab selected={sSelectedTab} setSelectedTab={setSelectedTab} />
            {sSelectedTab === enumModifyTab.password ? (
                <ModifyPassword />
            ) : (
                <BankSetting clickBtnText={"儲存並更新"} successFn={bankSuccessFN} returnFn={bankReturnFN} />
            )}
        </div>
    );
};

export default ModifyPage;
