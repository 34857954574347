import React, { useContext, useState } from 'react'
import BaseContext from '../../context/BaseContext'
import { VerifyAlert } from '../../util/dataProcess'

interface props {
    vSelectedImages: Blob[]
    setSelectedImages: React.Dispatch<React.SetStateAction<Blob[]>>
}

const UploadImage = ({ vSelectedImages, setSelectedImages }: props) => {
    const { setAlert } = useContext(BaseContext)
    const [vPreviewImage, setPreviewImage] = useState<string[]>([])
    const imageHandleChange = (e: any) => {
        if (e.target.files[0].size > 1048576) {
            VerifyAlert({
                title: '',
                content: `上傳圖片檔案過大！`,
                btnText: '返回',
                MsgType: 2,
                btnOnclick: () => {
                    setAlert('Msg', false)
                },
                setAlert: setAlert,
            })
        } else if (e.target.files) {
            setSelectedImages([...vSelectedImages, e.target.files[0]])
            setPreviewImage((prevImage) => prevImage.concat(URL.createObjectURL(e.target.files[0])))
        }
    }

    const renderPhotos = (source: string[]) => {
        if (source.length === 0) return
        return source.map((photo: string) => {
            return <img src={photo} key={photo} alt="" />
        })
    }

    return (
        <div id="ImageUpload">
            <input type="file" id="imageFile" onChange={imageHandleChange} />
            <div className="result">
                {renderPhotos(vPreviewImage)}
                {vPreviewImage.length < 5 && (
                    <div className="label-holder">
                        <label htmlFor="imageFile" className="label">
                            +
                        </label>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UploadImage
