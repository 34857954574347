import React, { useContext } from "react";
import BaseContext from "../context/BaseContext";
import { filter } from "../pages/BlogPage";

interface props {
    defaultText: string;
    setFilterSelect: React.Dispatch<React.SetStateAction<number>>;
    FilterSelect: number;
    FilterTab: filter[];
}

const Filter = ({ setFilterSelect, FilterSelect, defaultText, FilterTab }: props) => {
    const { setAlert } = useContext(BaseContext);

    const handleSelect = (selected: number) => {
        setFilterSelect(selected);
        setAlert("Modal", false);
    };

    const showSelectOption = () => {
        setAlert(
            "Modal",
            true,
            <div id="AlertFilter">
                <div className="title">{defaultText}</div>
                <div className="option">
                    {FilterTab.map((filterItem: filter) => {
                        return (
                            <div key={filterItem.Type} onClick={() => handleSelect(filterItem.Type)}>
                                {filterItem.button_text}
                            </div>
                        );
                    })}
                </div>
            </div>,
            "",
            0,
            false,
            true
        );
    };

    const retunFilterText = () => {
        const index = FilterTab.findIndex((item) => item.Type === FilterSelect);
        if (index === -1) {
            return defaultText;
        } else {
            return FilterTab[index].button_text;
        }
    };

    return (
        <div id="Filter" onClick={showSelectOption}>
            <span>{retunFilterText()}</span>
            <img src={process.env.PUBLIC_URL + "/img/icon-filter.svg"} alt="" />
        </div>
    );
};

export default Filter;
