import LoginNative from './LoginNative'
import LoginOauth from './LoginOauth'
import LoginFacebook from './LoginFacebook'
import { useContext, useEffect } from 'react'
import BaseContext from '../../context/BaseContext'
import { useLocation } from 'react-router-dom'

export interface oauthLoginInput {
    click_login: (access_token: string, id_token: string, refresh_token: string) => void
    CLIENT_ID: string
    CLIENT_SECRET: string
    LOCATION: string
}

export interface nativeLoginInput {
    click_login: (account: { email: string; password: string }) => void
    password_length_min: number
    password_length_max: number
}

// P2P
const REACT_APP_GOOGLE_CLIENT_ID = '79539728523-utlk5al8b0hpal79nhqr3t63bb2jckek.apps.googleusercontent.com'
const REACT_APP_CLIENT_SECRET = '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'

// //NPEx
// const REACT_APP_GOOGLE_CLIENT_ID = "996420267471-j21cm9k25afbek7hi2se0mo8826bk37a.apps.googleusercontent.com";
// const REACT_APP_CLIENT_SECRET = "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";

export default function Login() {
    const location = useLocation()
    const { loginOauth, nativeLogin } = useContext(BaseContext)

    const CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID ?? ''
    const CLIENT_SECRET = REACT_APP_CLIENT_SECRET ?? ''
    const LOCATION = window.location.origin

    const click_login_oauth = (email: string, name: string, googleId: string) => {
        loginOauth(email, name, googleId)
    }

    const click_login_native = (account: { email: string; password: string }) => {
        nativeLogin(account)
    }

    const oauthInput = {
        type: 'oauth',
        oauth: {
            click_login: click_login_oauth,
            CLIENT_ID,
            CLIENT_SECRET,
            LOCATION,
        },
    }

    const nativeInput = {
        type: 'native',
        native: {
            click_login: click_login_native,
            password_length_min: 6,
            password_length_max: 16,
        },
    }

    useEffect(() => {
        if (location.pathname.indexOf('login') > -1) document.title = '會員登入｜P2PRank'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const { type, native, oauth } = props;
    return (
        <div id="Login">
            <div className={`login-block ${location.pathname.indexOf('login') > -1 && 'login-page-mt'}`}>
                <div className="login-native">
                    <div className="title">會員登入</div>
                    {location.pathname.indexOf('login') === -1 && (
                        <label className="login-title-hint">請先登入帳號</label>
                    )}
                    <LoginNative {...nativeInput.native} />
                </div>
                <LoginFacebook />
                <LoginOauth {...oauthInput.oauth} />
                <div className="privacy-police">
                    <span className="under-line">隱私權條款</span>：我們僅將您所提供的資料應用於 P2PRank
                    相關必須上，全力保護 P2PRank 會員的會員資料
                </div>
            </div>
        </div>
    )
}
