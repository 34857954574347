import Input from "../../components/controls/Input";
import { useContext, useEffect, useState } from "react";
import { accountRegister, useForm } from "../../components/controls/useForm";
import Button from "../../components/Button";
import BaseContext from "../../context/BaseContext";
import { RegistAPI } from "../../util/api";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";

const initialValues: accountRegister = {
    name: "",
    email: "",
    password: "",
    oauth_from: "",
    oauth_id: "",
};

interface propState {
    name: string;
    email: string;
    id: string;
    from: string;
}

export default function Register() {
    const location = useLocation();
    let LocationName = "";
    let LocationEmail = "";
    let LocationID = "";
    let LocationFrom = "";
    if (location.state !== null) {
        const { name, email, id, from } = location.state as propState;
        LocationName = name;
        LocationEmail = email;
        LocationID = id;
        LocationFrom = from;
    }

    const initialRefisterValues: accountRegister = {
        name: LocationName,
        email: LocationEmail,
        password: "",
        oauth_from: "",
        oauth_id: "",
    };

    // context
    const { ChangeURL, updateNativeToken } = useContext(BaseContext);

    const [vRegistValues, setRegistValues] = useState<accountRegister>(initialRefisterValues);
    const [vDoublecheckPass, setDoublecheckPass] = useState<string>("");
    const [vDoublecheckPassError, setDoublecheckPassError] = useState<string>("");

    const handleDoublecheckPassInputChange = (e: any) => {
        setDoublecheckPass(e.target.value);
    };

    const { errors, setErrors, handleInputChange } = useForm(initialValues, vRegistValues, setRegistValues);

    const validate = (fieldValues = vRegistValues) => {
        let temp: accountRegister = { ...(errors as accountRegister) };
        if ("email" in fieldValues)
            temp.email =
                fieldValues.email === ""
                    ? "此欄位必填"
                    : /$^|.+@.+..+/.test(fieldValues.email)
                    ? ""
                    : "請輸入有效的信箱";
        if ("password" in fieldValues)
            temp.password =
                fieldValues.password === ""
                    ? "此欄位必填"
                    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(fieldValues.password)
                    ? ""
                    : "密碼請設定至少含有8碼大小寫英文、數字或符號";
        if ("name" in fieldValues) temp.name = fieldValues.name === "" ? "此欄位必填" : "";
        setErrors({
            ...temp,
        });
        if (fieldValues === vRegistValues) return Object.values(temp).every((x) => x === "");
    };

    const checkDoublePassword = () => {
        if (vDoublecheckPass === "") {
            setDoublecheckPassError("此欄位必填");
        } else if (vRegistValues.password !== vDoublecheckPass) {
            setDoublecheckPassError("與密碼不同");
        } else {
            setDoublecheckPassError("");
        }
        return vRegistValues.password === vDoublecheckPass;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate() && checkDoublePassword()) {
            if (LocationID !== "" && LocationFrom !== "") {
                vRegistValues.oauth_from = LocationFrom;
                vRegistValues.oauth_id = LocationID;
            }
            RegistAPI(vRegistValues).then((res: any) => {
                if (res.success) {
                    updateNativeToken(res.token);
                    ChangeURL("/login/register/invite");
                } else {
                    if (res.email_exist) {
                        let temp: accountRegister = { ...(initialValues as accountRegister) };
                        temp.email = "此 Email 已註冊過";
                        setErrors({ ...temp });
                    }
                }
            });
        }
    };

    useEffect(() => {
        document.title = "建立帳號｜P2PRank";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="Register">
            <Breadcrumb locationPath={location.pathname} />
            <div className="register-block">
                <div className="title">建立您的 P2PRank 帳戶</div>
                <div className="input-block">
                    <Input
                        name="name"
                        label="暱稱"
                        value={vRegistValues.name}
                        onChange={(e) => handleInputChange(e)}
                        error={errors.name}
                        type={"text"}
                        hasPlaceholder={false}
                    />
                    <Input
                        name="email"
                        label="電子郵件地址"
                        value={vRegistValues.email}
                        onChange={(e) => handleInputChange(e)}
                        error={errors.email}
                        type={"text"}
                        hasPlaceholder={false}
                    />
                    <Input
                        name="password"
                        label="密碼"
                        value={vRegistValues.password}
                        onChange={(e) => handleInputChange(e)}
                        error={errors.password}
                        type={"password"}
                        hasPlaceholder={false}
                    />
                    <p className="hint">請混合使用 8 個字元以上的大小寫英文字母、數字和符號</p>
                    <Input
                        name="password"
                        label="確認密碼"
                        value={vDoublecheckPass}
                        onChange={(e) => handleDoublecheckPassInputChange(e)}
                        error={vDoublecheckPassError}
                        type={"password"}
                        hasPlaceholder={false}
                    />
                </div>
                <div className="register-btn">
                    <Button isShowIcon={false} btnName="註冊" onclickFn={(e) => handleSubmit(e)} />
                </div>
                <div className="privacy-police">
                    一旦點擊註冊，即表示您同意 P2PRank 的。<span className="under-line">隱私權條款</span>
                </div>
            </div>
        </div>
    );
}
