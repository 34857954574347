import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import PhoneVerify, { InputCodeNumber } from "../../components/Register/PhoneVerify";
import BaseContext from "../../context/BaseContext";
import { VerifyAlert } from "../../util/dataProcess";

export default function InputPhone() {
    const location = useLocation();
    const [sUserPhone, setUserPhone] = useState<string>("");
    const [sPhoneVerify, setPhoneVerify] = useState<InputCodeNumber>({
        "number-1": "",
        "number-2": "",
        "number-3": "",
        "number-4": "",
        "number-5": "",
        "number-6": "",
    });

    // context
    const { ChangeURL, setAlert } = useContext(BaseContext);

    useEffect(() => {
        document.title = "帳號驗證｜P2PRank";
    }, []);

    return (
        <div id="UserPhone">
            <Breadcrumb locationPath={location.pathname} />
            <div className="userphone-block">
                <div className="title">輸入電話號碼</div>
                <PhoneVerify
                    sPhoneVerify={sPhoneVerify}
                    setPhoneVerify={setPhoneVerify}
                    sUserPhone={sUserPhone}
                    setUserPhone={setUserPhone}
                    successFn={() => {
                        VerifyAlert({
                            title: "驗證成功",
                            content: "現在您可以開始使用 P2PRank 的完整功能！",
                            btnText: "返回首頁",
                            MsgType: 1,
                            btnOnclick: () => {
                                ChangeURL("/");
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    }}
                />
            </div>
        </div>
    );
}
