export const bankCode = [
    {
        value: "004",
        label: "臺灣銀行",
    },
    {
        value: "005",
        label: "臺灣土地銀行",
    },
    {
        value: "006",
        label: "合作金庫商業銀行",
    },
    {
        value: "007",
        label: "第一商業銀行",
    },
    {
        value: "008",
        label: "華南商業銀行",
    },
    {
        value: "009",
        label: "彰化商業銀行",
    },
    {
        value: "011",
        label: "上海商業儲蓄銀行",
    },
    {
        value: "012",
        label: "台北富邦商業銀行",
    },
    {
        value: "013",
        label: "國泰世華商業銀行",
    },
    {
        value: "016",
        label: "高雄銀行",
    },
    {
        value: "017",
        label: "兆豐國際商業銀行",
    },
    {
        value: "018",
        label: "全國農業金庫",
    },
    {
        value: "020",
        label: "日商瑞穗銀行台北分行",
    },
    {
        value: "021",
        label: "花旗（台灣）商業銀行",
    },
    {
        value: "022",
        label: "美國銀行台北分行",
    },
    {
        value: "023",
        label: "泰國盤谷銀行台北分行",
    },
    {
        value: "025",
        label: "菲律賓首都銀行台北分行",
    },
    {
        value: "029",
        label: "新加坡商大華銀行台北分行",
    },
    {
        value: "030",
        label: "美商道富銀行台北分行",
    },
    {
        value: "037",
        label: "法商法國興業銀行台北分行",
    },
    {
        value: "039",
        label: "澳商澳盛銀行台北分行",
    },
    {
        value: "048",
        label: "王道商業銀行",
    },
    {
        value: "050",
        label: "臺灣中小企業銀行",
    },
    {
        value: "052",
        label: "渣打國際商業銀行",
    },
    {
        value: "053",
        label: "台中商業銀行",
    },
    {
        value: "054",
        label: "京城商業銀行",
    },
    {
        value: "060",
        label: "兆豐票券金融股份有限公司",
    },
    {
        value: "061",
        label: "中華票券金融股份有限公司",
    },
    {
        value: "062",
        label: "國際票券金融股份有限公司",
    },
    {
        value: "066",
        label: "萬通票券金融股份有限公司",
    },
    {
        value: "072",
        label: "德商德意志銀行台北分行",
    },
    {
        value: "075",
        label: "香港商東亞銀行台北分行",
    },
    {
        value: "076",
        label: "美商摩根大通銀行台北分行",
    },
    {
        value: "081",
        label: "匯豐（台灣）商業銀行",
    },
    {
        value: "082",
        label: "法國巴黎銀行台北分行",
    },
    {
        value: "085",
        label: "新加坡商新加坡華僑銀行台北分行",
    },
    {
        value: "086",
        label: "法商東方匯理銀行台北分行",
    },
    {
        value: "092",
        label: "瑞士商瑞士銀行台北分行",
    },
    {
        value: "093",
        label: "荷商安智銀行台北分行",
    },
    {
        value: "098",
        label: "日商三菱日聯銀行台北分行",
    },
    {
        value: "101",
        label: "瑞興商業銀行",
    },
    {
        value: "102",
        label: "華泰商業銀行",
    },
    {
        value: "103",
        label: "臺灣新光商業銀行",
    },
    {
        value: "104",
        label: "台北市第五信用合作社",
    },
    {
        value: "108",
        label: "陽信商業銀行",
    },
    {
        value: "114",
        label: "基隆第一信用合作社",
    },
    {
        value: "115",
        label: "基隆市第二信用合作社",
    },
    {
        value: "118",
        label: "板信商業銀行",
    },
    {
        value: "119",
        label: "淡水第一信用合作社",
    },
    {
        value: "120",
        label: "新北市淡水信用合作社",
    },
    {
        value: "124",
        label: "宜蘭信用合作社",
    },
    {
        value: "127",
        label: "桃園信用合作社",
    },
    {
        value: "130",
        label: "新竹第一信用合作社",
    },
    {
        value: "132",
        label: "新竹第三信用合作社",
    },
    {
        value: "146",
        label: "台中市第二信用合作社",
    },
    {
        value: "147",
        label: "三信商業銀行",
    },
    {
        value: "158",
        label: "彰化第一信用合作社",
    },
    {
        value: "161",
        label: "彰化第五信用合作社",
    },
    {
        value: "162",
        label: "彰化第六信用合作社",
    },
    {
        value: "163",
        label: "彰化第十信用合作社",
    },
    {
        value: "165",
        label: "彰化縣鹿港信用合作社",
    },
    {
        value: "178",
        label: "嘉義市第三信用合作社",
    },
    {
        value: "188",
        label: "台南第三信用合作社",
    },
    {
        value: "204",
        label: "高雄市第三信用合作社",
    },
    {
        value: "215",
        label: "花蓮第一信用合作社",
    },
    {
        value: "216",
        label: "花蓮第二信用合作社",
    },
    {
        value: "222",
        label: "澎湖縣第一信用合作社",
    },
    {
        value: "223",
        label: "澎湖第二信用合作社",
    },
    {
        value: "224",
        label: "金門縣信用合作社",
    },
    {
        value: "321",
        label: "日商三井住友銀行台北分行",
    },
    {
        value: "326",
        label: "西班牙商西班牙對外銀行臺北分行",
    },
    {
        value: "329",
        label: "印尼商印尼人民銀行台北分行",
    },
    {
        value: "330",
        label: "韓商韓亞銀行台北分行",
    },
    {
        value: "372",
        label: "大慶票券金融股份有限公司",
    },
    {
        value: "380",
        label: "大陸商中國銀行臺北分行",
    },
    {
        value: "381",
        label: "大陸商交通銀行臺北分行",
    },
    {
        value: "382",
        label: "大陸商中國建設銀行臺北分行",
    },
    {
        value: "501",
        label: "宜蘭縣蘇澳區漁會（農金資訊所屬會員）",
    },
    {
        value: "502",
        label: "宜蘭縣頭城區漁會（農金資訊所屬會員）",
    },
    {
        value: "506",
        label: "桃園區漁會（農金資訊所屬會員）",
    },
    {
        value: "507",
        label: "新竹區漁會（農金資訊所屬會員）",
    },
    {
        value: "508",
        label: "通苑區漁會（農金資訊所屬會員）",
    },
    {
        value: "510",
        label: "南龍區漁會（農金資訊所屬會員）",
    },
    {
        value: "511",
        label: "彰化區漁會（農金資訊所屬會員）",
    },
    {
        value: "512",
        label: "南農中心所屬會員",
    },
    {
        value: "513",
        label: "新北市瑞芳區漁會（農金資訊所屬會員）",
    },
    {
        value: "514",
        label: "萬里區漁會（農金資訊所屬會員）",
    },
    {
        value: "515",
        label: "南農中心所屬會員",
    },
    {
        value: "516",
        label: "基隆區漁會（農金資訊所屬會員）",
    },
    {
        value: "517",
        label: "南農中心所屬會員",
    },
    {
        value: "518",
        label: "南農中心所屬會員",
    },
    {
        value: "519",
        label: "新化區農會（農金資訊所屬會員）",
    },
    {
        value: "520",
        label: "南農中心所屬會員",
    },
    {
        value: "521",
        label: "南農中心所屬會員",
    },
    {
        value: "523",
        label: "南農中心所屬會員",
    },
    {
        value: "524",
        label: "南農中心所屬會員",
    },
    {
        value: "525",
        label: "南農中心所屬會員",
    },
    {
        value: "526",
        label: "南農中心所屬會員",
    },
    {
        value: "538",
        label: "宜蘭市農會（農金資訊所屬會員）",
    },
    {
        value: "541",
        label: "白河區農會（農金資訊所屬會員）",
    },
    {
        value: "542",
        label: "麻豆區農會（農金資訊所屬會員）",
    },
    {
        value: "547",
        label: "後壁區農會（農金資訊所屬會員）",
    },
    {
        value: "549",
        label: "下營區農會（農金資訊所屬會員）",
    },
    {
        value: "551",
        label: "官田區農會（農金資訊所屬會員）",
    },
    {
        value: "552",
        label: "大內區農會（農金資訊所屬會員）",
    },
    {
        value: "556",
        label: "學甲區農會（農金資訊所屬會員）",
    },
    {
        value: "557",
        label: "新市區農會（農金資訊所屬會員）",
    },
    {
        value: "558",
        label: "安定區農會（農金資訊所屬會員）",
    },
    {
        value: "559",
        label: "山上區農會（農金資訊所屬會員）",
    },
    {
        value: "561",
        label: "左鎮區農會（農金資訊所屬會員）",
    },
    {
        value: "562",
        label: "仁德區農會（農金資訊所屬會員）",
    },
    {
        value: "564",
        label: "關廟區農會（農金資訊所屬會員）",
    },
    {
        value: "565",
        label: "龍崎區農會（農金資訊所屬會員）",
    },
    {
        value: "567",
        label: "南化區農會（農金資訊所屬會員）",
    },
    {
        value: "568",
        label: "七股區農會（農金資訊所屬會員）",
    },
    {
        value: "570",
        label: "南投市農會（農金資訊所屬會員）",
    },
    {
        value: "573",
        label: "埔里鎮農會（農金資訊所屬會員）",
    },
    {
        value: "574",
        label: "竹山鎮農會（農金資訊所屬會員）",
    },
    {
        value: "575",
        label: "中寮鄉農會（農金資訊所屬會員）",
    },
    {
        value: "577",
        label: "魚池鄉農會（農金資訊所屬會員）",
    },
    {
        value: "578",
        label: "水里鄉農會（農金資訊所屬會員）",
    },
    {
        value: "579",
        label: "國姓鄉農會（農金資訊所屬會員）",
    },
    {
        value: "580",
        label: "鹿谷鄉農會（農金資訊所屬會員）",
    },
    {
        value: "581",
        label: "信義鄉農會（農金資訊所屬會員）",
    },
    {
        value: "582",
        label: "仁愛鄉農會（農金資訊所屬會員）",
    },
    {
        value: "583",
        label: "東山區農會（農金資訊所屬會員）",
    },
    {
        value: "585",
        label: "頭城鎮農會（農金資訊所屬會員）",
    },
    {
        value: "586",
        label: "羅東鎮農會（農金資訊所屬會員）",
    },
    {
        value: "587",
        label: "礁溪鄉農會（農金資訊所屬會員）",
    },
    {
        value: "588",
        label: "壯圍鄉農會（農金資訊所屬會員）",
    },
    {
        value: "589",
        label: "員山鄉農會（農金資訊所屬會員）",
    },
    {
        value: "596",
        label: "五結鄉農會（農金資訊所屬會員）",
    },
    {
        value: "598",
        label: "蘇澳地區農會（農金資訊所屬會員）",
    },
    {
        value: "599",
        label: "三星地區農會（農金資訊所屬會員）",
    },
    {
        value: "600",
        label: "農金資訊股份有限公司",
    },
    {
        value: "602",
        label: "中華民國農會中壢辦事處信用部（農金資訊所",
    },
    {
        value: "605",
        label: "高雄市高雄地區農會（農金資訊所屬會員）",
    },
    {
        value: "612",
        label: "南農中心所屬會員",
    },
    {
        value: "613",
        label: "南農中心所屬會員",
    },
    {
        value: "614",
        label: "南農中心所屬會員",
    },
    {
        value: "615",
        label: "基隆市農會（農金資訊所屬會員）",
    },
    {
        value: "616",
        label: "南農中心所屬會員",
    },
    {
        value: "617",
        label: "南農中心所屬會員",
    },
    {
        value: "618",
        label: "南農中心所屬會員",
    },
    {
        value: "619",
        label: "南農中心所屬會員",
    },
    {
        value: "620",
        label: "南農中心所屬會員",
    },
    {
        value: "621",
        label: "南農中心所屬會員",
    },
    {
        value: "622",
        label: "南農中心所屬會員",
    },
    {
        value: "624",
        label: "南農中心所屬會員",
    },
    {
        value: "625",
        label: "臺中市臺中地區農會（農金資訊所屬會員）",
    },
    {
        value: "627",
        label: "南農中心所屬會員",
    },
    {
        value: "628",
        label: "鹿港鎮農會（農金資訊所屬會員）",
    },
    {
        value: "629",
        label: "和美鎮農會（農金資訊所屬會員）",
    },
    {
        value: "631",
        label: "溪湖鎮農會（農金資訊所屬會員）",
    },
    {
        value: "632",
        label: "田中鎮農會（農金資訊所屬會員）",
    },
    {
        value: "633",
        label: "北斗鎮農會（農金資訊所屬會員）",
    },
    {
        value: "635",
        label: "線西鄉農會（農金資訊所屬會員）",
    },
    {
        value: "636",
        label: "伸港鄉農會（農金資訊所屬會員）",
    },
    {
        value: "638",
        label: "花壇鄉農會（農金資訊所屬會員）",
    },
    {
        value: "639",
        label: "大村鄉農會（農金資訊所屬會員）",
    },
    {
        value: "642",
        label: "社頭鄉農會（農金資訊所屬會員）",
    },
    {
        value: "643",
        label: "二水鄉農會（農金資訊所屬會員）",
    },
    {
        value: "646",
        label: "大城鄉農會（農金資訊所屬會員）",
    },
    {
        value: "647",
        label: "溪州鄉農會（農金資訊所屬會員）",
    },
    {
        value: "649",
        label: "埔鹽鄉農會（農金資訊所屬會員）",
    },
    {
        value: "650",
        label: "福興鄉農會（農金資訊所屬會員）",
    },
    {
        value: "651",
        label: "彰化市農會（農金資訊所屬會員）",
    },
    {
        value: "683",
        label: "北港鎮農會（農金資訊所屬會員）",
    },
    {
        value: "685",
        label: "土庫鎮農會（農金資訊所屬會員）",
    },
    {
        value: "693",
        label: "東勢鄉農會（農金資訊所屬會員）",
    },
    {
        value: "696",
        label: "水林鄉農會（農金資訊所屬會員）",
    },
    {
        value: "697",
        label: "元長鄉農會（農金資訊所屬會員）",
    },
    {
        value: "698",
        label: "麥寮鄉農會（農金資訊所屬會員）",
    },
    {
        value: "699",
        label: "林內鄉農會（農金資訊所屬會員）",
    },
    {
        value: "700",
        label: "中華郵政股份有限公司",
    },
    {
        value: "749",
        label: "內埔地區農會（農金資訊所屬會員）",
    },
    {
        value: "762",
        label: "大溪區農會（農金資訊所屬會員）",
    },
    {
        value: "763",
        label: "桃園區農會（農金資訊所屬會員）",
    },
    {
        value: "764",
        label: "平鎮區農會（農金資訊所屬會員）",
    },
    {
        value: "765",
        label: "楊梅區農會（農金資訊所屬會員）",
    },
    {
        value: "766",
        label: "大園區農會（農金資訊所屬會員）",
    },
    {
        value: "767",
        label: "蘆竹區農會（農金資訊所屬會員）",
    },
    {
        value: "768",
        label: "龜山區農會（農金資訊所屬會員）",
    },
    {
        value: "769",
        label: "八德區農會（農金資訊所屬會員）",
    },
    {
        value: "770",
        label: "新屋區農會（農金資訊所屬會員）",
    },
    {
        value: "771",
        label: "龍潭區農會（農金資訊所屬會員）",
    },
    {
        value: "772",
        label: "復興區農會（農金資訊所屬會員）",
    },
    {
        value: "773",
        label: "觀音區農會（農金資訊所屬會員）",
    },
    {
        value: "775",
        label: "土城區農會（農金資訊所屬會員）",
    },
    {
        value: "776",
        label: "三重區農會（農金資訊所屬會員）",
    },
    {
        value: "777",
        label: "中和地區農會（農金資訊所屬會員）",
    },
    {
        value: "778",
        label: "淡水區農會（農金資訊所屬會員）",
    },
    {
        value: "779",
        label: "樹林區農會（農金資訊所屬會員）",
    },
    {
        value: "780",
        label: "鶯歌區農會（農金資訊所屬會員）",
    },
    {
        value: "781",
        label: "三峽區農會（農金資訊所屬會員）",
    },
    {
        value: "785",
        label: "蘆洲區農會（農金資訊所屬會員）",
    },
    {
        value: "786",
        label: "五股區農會（農金資訊所屬會員）",
    },
    {
        value: "787",
        label: "林口區農會（農金資訊所屬會員）",
    },
    {
        value: "788",
        label: "泰山區農會（農金資訊所屬會員）",
    },
    {
        value: "789",
        label: "坪林區農會（農金資訊所屬會員）",
    },
    {
        value: "790",
        label: "八里區農會（農金資訊所屬會員）",
    },
    {
        value: "791",
        label: "金山地區農會（農金資訊所屬會員）",
    },
    {
        value: "792",
        label: "瑞芳地區農會（農金資訊所屬會員）",
    },
    {
        value: "793",
        label: "新店地區農會（農金資訊所屬會員）",
    },
    {
        value: "795",
        label: "深坑區農會（農金資訊所屬會員）",
    },
    {
        value: "796",
        label: "石碇區農會（農金資訊所屬會員）",
    },
    {
        value: "797",
        label: "平溪區農會（農金資訊所屬會員）",
    },
    {
        value: "798",
        label: "石門區農會（農金資訊所屬會員）",
    },
    {
        value: "799",
        label: "三芝區農會（農金資訊所屬會員）",
    },
    {
        value: "803",
        label: "聯邦商業銀行",
    },
    {
        value: "805",
        label: "遠東國際商業銀行",
    },
    {
        value: "806",
        label: "元大商業銀行",
    },
    {
        value: "807",
        label: "永豐商業銀行",
    },
    {
        value: "808",
        label: "玉山商業銀行",
    },
    {
        value: "809",
        label: "凱基商業銀行",
    },
    {
        value: "810",
        label: "星展（台灣）商業銀行",
    },
    {
        value: "812",
        label: "台新國際商業銀行",
    },
    {
        value: "815",
        label: "日盛國際商業銀行",
    },
    {
        value: "816",
        label: "安泰商業銀行",
    },
    {
        value: "822",
        label: "中國信託商業銀行",
    },
    {
        value: "823",
        label: "將來商業銀行",
    },
    {
        value: "824",
        label: "連線商業銀行",
    },
    {
        value: "826",
        label: "樂天國際商業銀行",
    },
    {
        value: "860",
        label: "中埔鄉農會（農金資訊所屬會員）",
    },
    {
        value: "866",
        label: "阿里山鄉農會（農金資訊所屬會員）",
    },
    {
        value: "868",
        label: "東勢區農會（農金資訊所屬會員）",
    },
    {
        value: "869",
        label: "清水區農會（農金資訊所屬會員）",
    },
    {
        value: "870",
        label: "梧棲區農會（農金資訊所屬會員）",
    },
    {
        value: "871",
        label: "大甲區農會（農金資訊所屬會員）",
    },
    {
        value: "872",
        label: "沙鹿區農會（農金資訊所屬會員）",
    },
    {
        value: "874",
        label: "霧峰區農會（農金資訊所屬會員）",
    },
    {
        value: "875",
        label: "太平區農會（農金資訊所屬會員）",
    },
    {
        value: "876",
        label: "烏日區農會（農金資訊所屬會員）",
    },
    {
        value: "877",
        label: "后里區農會（農金資訊所屬會員）",
    },
    {
        value: "878",
        label: "大雅區農會（農金資訊所屬會員）",
    },
    {
        value: "879",
        label: "潭子區農會（農金資訊所屬會員）",
    },
    {
        value: "880",
        label: "石岡區農會（農金資訊所屬會員）",
    },
    {
        value: "881",
        label: "新社區農會（農金資訊所屬會員）",
    },
    {
        value: "882",
        label: "大肚區農會（農金資訊所屬會員）",
    },
    {
        value: "883",
        label: "外埔區農會（農金資訊所屬會員）",
    },
    {
        value: "884",
        label: "大安區農會（農金資訊所屬會員）",
    },
    {
        value: "885",
        label: "龍井區農會（農金資訊所屬會員）",
    },
    {
        value: "886",
        label: "和平區農會（農金資訊所屬會員）",
    },
    {
        value: "891",
        label: "花蓮市農會（農金資訊所屬會員）",
    },
    {
        value: "895",
        label: "瑞穗鄉農會（農金資訊所屬會員）",
    },
    {
        value: "896",
        label: "玉溪地區農會（農金資訊所屬會員）",
    },
    {
        value: "897",
        label: "鳳榮地區農會（農金資訊所屬會員）",
    },
    {
        value: "898",
        label: "光豐地區農會（農金資訊所屬會員）",
    },
    {
        value: "901",
        label: "大里區農會（農金資訊所屬會員）",
    },
    {
        value: "902",
        label: "苗栗市農會（農金資訊所屬會員）",
    },
    {
        value: "903",
        label: "汐止區農會（農金資訊所屬會員）",
    },
    {
        value: "904",
        label: "新莊區農會（農金資訊所屬會員）",
    },
    {
        value: "906",
        label: "頭份市農會（農金資訊所屬會員）",
    },
    {
        value: "907",
        label: "竹南鎮農會（農金資訊所屬會員）",
    },
    {
        value: "908",
        label: "通霄鎮農會（農金資訊所屬會員）",
    },
    {
        value: "909",
        label: "苑裡鎮農會（農金資訊所屬會員）",
    },
    {
        value: "912",
        label: "冬山鄉農會（農金資訊所屬會員）",
    },
    {
        value: "913",
        label: "後龍鎮農會（農金資訊所屬會員）",
    },
    {
        value: "914",
        label: "卓蘭鎮農會（農金資訊所屬會員）",
    },
    {
        value: "915",
        label: "西湖鄉農會（農金資訊所屬會員）",
    },
    {
        value: "916",
        label: "草屯鎮農會（農金資訊所屬會員）",
    },
    {
        value: "917",
        label: "公館鄉農會（農金資訊所屬會員）",
    },
    {
        value: "918",
        label: "銅鑼鄉農會（農金資訊所屬會員）",
    },
    {
        value: "919",
        label: "三義鄉農會（農金資訊所屬會員）",
    },
    {
        value: "920",
        label: "造橋鄉農會（農金資訊所屬會員）",
    },
    {
        value: "921",
        label: "南庄鄉農會（農金資訊所屬會員）",
    },
    {
        value: "922",
        label: "臺南市臺南地區農會（農金資訊所屬會員）",
    },
    {
        value: "923",
        label: "獅潭鄉農會（農金資訊所屬會員）",
    },
    {
        value: "924",
        label: "頭屋鄉農會（農金資訊所屬會員）",
    },
    {
        value: "925",
        label: "三灣鄉農會（農金資訊所屬會員）",
    },
    {
        value: "926",
        label: "苗栗縣大湖地區農會（農金資訊所屬會員）",
    },
    {
        value: "928",
        label: "板橋區農會（農金資訊所屬會員）",
    },
    {
        value: "929",
        label: "關西鎮農會（農金資訊所屬會員）",
    },
    {
        value: "930",
        label: "新埔鎮農會（農金資訊所屬會員）",
    },
    {
        value: "931",
        label: "竹北市農會（農金資訊所屬會員）",
    },
    {
        value: "932",
        label: "湖口鄉農會（農金資訊所屬會員）",
    },
    {
        value: "933",
        label: "芎林鄉農會（農金資訊所屬會員）",
    },
    {
        value: "934",
        label: "寶山鄉農會（農金資訊所屬會員）",
    },
    {
        value: "935",
        label: "峨眉鄉農會（農金資訊所屬會員）",
    },
    {
        value: "936",
        label: "北埔鄉農會（農金資訊所屬會員）",
    },
    {
        value: "937",
        label: "竹東地區農會（農金資訊所屬會員）",
    },
    {
        value: "938",
        label: "橫山地區農會（農金資訊所屬會員）",
    },
    {
        value: "939",
        label: "新豐鄉農會（農金資訊所屬會員）",
    },
    {
        value: "940",
        label: "新竹市農會信用部（農金資訊所屬會員）",
    },
    {
        value: "952",
        label: "南農中心所屬會員",
    },
    {
        value: "952",
        label: "財團法人農漁會南區資訊中心",
    },
    {
        value: "953",
        label: "田尾鄉農會（農金資訊所屬會員）",
    },
    {
        value: "984",
        label: "北投區農會（農金資訊所屬會員）",
    },
    {
        value: "985",
        label: "士林區農會（農金資訊所屬會員）",
    },
    {
        value: "986",
        label: "內湖區農會（農金資訊所屬會員）",
    },
    {
        value: "987",
        label: "南港區農會（農金資訊所屬會員）",
    },
    {
        value: "988",
        label: "木柵區農會（農金資訊所屬會員）",
    },
    {
        value: "989",
        label: "景美區農會（農金資訊所屬會員）",
    },
    {
        value: "995",
        label: "關貿網路股份有限公司",
    },
    {
        value: "996",
        label: "財政部國庫署",
    },
    {
        value: "997",
        label: "中華民國信用合作社聯合社南區聯合資訊處理",
    },
];
