import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseContext from "../../context/BaseContext";
import { FilterTab, iArticle } from "../../pages/BlogPage";
import { articleDetail, getBlogDetailFromAPI } from "../../util/api";
import { VerifyAlert } from "../../util/dataProcess";
import Button from "../Button";
import Marquees from "../Marquees";
import ArticleListCard from "./ArticleListCard";
import YouTube, { YouTubeProps } from "react-youtube";

const ArticleDetail = () => {
    const { id } = useParams();
    const { vArticleData, ChangeURL } = useContext(BaseContext);
    const [data, setdata] = useState<articleDetail>({
        success: false,
        article: {
            article_code: "",
            title: "",
            subtitle: "",
            tags: [],
            image: "",
            article_type: 0,
            created_at: "",
            page_views: 0,
            is_top: 0,
            content: [],
            og_title: "",
            og_description: "",
            og_image: "",
        },
    });

    const { errorProcess, setAlert, sBanner } = useContext(BaseContext);

    const getBlogDetail = () => {
        if (id)
            getBlogDetailFromAPI(id)
                .then((res: any) => {
                    if (res.success) {
                        setdata(res);
                        document.title = res.article.title;
                    } else {
                        //載入錯誤,回部落格頁
                        VerifyAlert({
                            title: "文章載入失敗！",
                            content: "",
                            btnText: "返回部落格",
                            MsgType: 2,
                            btnOnclick: () => {
                                ChangeURL("/blog");
                                setAlert("Msg", false);
                            },
                            setAlert: setAlert,
                        });
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
    };

    const YouTubeGetID = (url: string) => {
        let urlID = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return urlID[2] !== undefined ? urlID[2].split(/[^0-9a-z_\-]/i)[0] : urlID[0];
    };

    const onPlayerReady: YouTubeProps["onReady"] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    const opts: YouTubeProps["opts"] = {
        height: "390px",
        width: "100%",
    };

    useEffect(() => {
        getBlogDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div id="ArticleDetail">
            <Marquees />
            {data.success && (
                <>
                    <div className="article-detail-back">
                        <div className="article-detail-cotent">
                            <img className="title-image" src={data.article.image} alt="" />
                            <div className="article-item-class-blur">
                                <div className="article-item-class">
                                    {
                                        FilterTab[
                                            FilterTab.findIndex((item) => item.Type === data.article.article_type)
                                        ].button_text
                                    }
                                </div>
                            </div>
                            <h1 className="title">{data.article.title}</h1>
                            <div className="hint">
                                {data.article.tags !== null &&
                                    data.article.tags.map((item: { value: string }) => {
                                        return <span key={item.value}>#{item.value}</span>;
                                    })}
                            </div>
                            <div className="date">{data.article.created_at.split(" ")[0].replaceAll("-", ".")}</div>
                            {data.article.content.map((item: any, idx: number) => {
                                switch (item.type) {
                                    case "text-introduction":
                                        return (
                                            <div className="og_description" key={idx}>
                                                {item.text}
                                            </div>
                                        );
                                    case "text-title":
                                        return (
                                            <h1 className="content-title" key={idx}>
                                                {item.text}
                                            </h1>
                                        );
                                    case "text-subtitle":
                                        return (
                                            <h2 className="content-title" key={idx}>
                                                {item.text}
                                            </h2>
                                        );
                                    case "text-subsubtitle":
                                        return (
                                            <h3 className="content-title" key={idx}>
                                                {item.text}
                                            </h3>
                                        );
                                    case "text-content":
                                        return (
                                            <div
                                                className="content"
                                                key={idx}
                                                dangerouslySetInnerHTML={{ __html: item.text }}
                                            ></div>
                                        );
                                    case "single-image":
                                        return (
                                            <>
                                                <img className="img" key={idx} src={item.image} alt={item.alt} />
                                                <span className="img-from">{item.other}</span>
                                            </>
                                        );
                                    case "divider":
                                        return <hr className="hr-css" />;
                                    case "youtube":
                                        const ID = YouTubeGetID(item.url);
                                        return (
                                            <YouTube
                                                className="youtube"
                                                videoId={ID}
                                                opts={opts}
                                                onReady={onPlayerReady}
                                            />
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                        <div className="risk-img">
                            <img
                                src={sBanner.img}
                                onClick={() => {
                                    window.location.href = sBanner.url;
                                }}
                                alt=""
                            />
                        </div>
                        <div className="related-article">
                            <div className="related-article-title">相關消息</div>
                            <div className="related-article-item">
                                {vArticleData
                                    .filter(
                                        (item: iArticle) =>
                                            item.article_type === data.article.article_type &&
                                            item.article_code !== data.article.article_code
                                    )
                                    .slice(0, 3)
                                    .map((article: iArticle, index: number) => {
                                        return <ArticleListCard key={index} Article={article} />;
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="ranking-block">
                        <div className="ranking-info-block">
                            <div className="ranking-block-title">探索 P2P 平台排名</div>
                            <img
                                className="ranking-img-mobile"
                                src={process.env.PUBLIC_URL + "/img/pic_rank.png"}
                                alt=""
                            />
                            <div className="ranking-block-info">
                                我們以平台用戶的使用評價，為平台進行排名。立即探索台灣的P2P平台，獲取完整的平台資訊。
                            </div>
                            <div className="ranking-block-btn">
                                <Button
                                    isShowIcon={false}
                                    onclickFn={() => ChangeURL("/rankpage")}
                                    btnName="查看完整列表"
                                />
                            </div>
                        </div>
                        <img
                            className="ranking-img-desktop"
                            src={process.env.PUBLIC_URL + "/img/pic_rank.png"}
                            alt=""
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ArticleDetail;
