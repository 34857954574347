import { useMemo } from "react";

interface pagination {
    totalCount: number;
    pageSize: number;
    siblingCount: number;
    currentPage: number;
}

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: pagination) => {
    //use -1 to represent DOTS ...
    const paginationRange: number[] = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);
        const totalPageNumbers = siblingCount + 5;
        //如果 sibling 少於全部頁數，則只顯示總頁數
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }
        //sibling 大於全部頁數，計算左右數字
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;
        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 2 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
            return [...leftRange, -1, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 2 + 2 * siblingCount;
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, -1, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, -1, ...middleRange, -1, lastPageIndex];
        }

        return [];
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
};
