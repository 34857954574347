import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Layout from './components/Layout'
import Loading from './components/Loading'
import Message from './components/Message'
import Modal from './components/Modal'
import { Provider, vUserInfoDefault } from './context/BaseContext'
import { iArticle } from './pages/BlogPage'
import routes from './router/router'
import './styles/App.scss'
import {
    getArticleList,
    getBanner,
    getUserInfo,
    loginFaceBookUser,
    loginGoogleUser,
    loginNativeUser,
    verifyToken,
} from './util/api'
import { VerifyAlert } from './util/dataProcess'
import ScrollToTop from './util/ScrollToTop'

// user info
export const vTokenType = ['native', 'accounts.google.com']

export interface vTokenInfo {
    token_iss: string
    token_iat: number
    token_exp: number
}

export interface vUserOutput extends vTokenInfo {
    user_id: number
    user_type: number
    user_name: string
    user_email: string
    user_account: string
}

export interface UserInfo {
    name: string
    user_id: null | string
    user_code: string
    phone: string
    share_code: string
    email: string
    points: number
    photo: string
    bank_code: null | string
    bank_branch: null | string
    bank_account_number: null | string
    bank_account_name: null | string
    birth: null | string
    comment_count: number
    response_count: number
    has_bank_info: boolean
}

export interface vTokenOutput extends vUserOutput, vTokenInfo {
    token_type: string
    access_token: string
    id_token: string
}

const App = () => {
    const navigate = useNavigate()
    const location = useLocation()

    // login
    const bAuth = useRef(false)
    const beLogin = useRef(false) //判斷登入後走入手機驗證流程
    const [vUserInfo, setvUserInfo] = useState<UserInfo>(vUserInfoDefault)
    // const sTokenType = useRef<string>(""); // oauth / native
    const sIdToken = useRef('') // oauth / native
    const sAccessToken = useRef('') // oauth
    const sRefreshToken = useRef('') // oauth

    // modal & alert state
    const [bClickBg, setClickBg] = useState(false)

    const [bModal, setbModal] = useState(false)
    const [sModal, setsModal] = useState(<></>)
    const [sModalTitle, setsModalTitle] = useState('')

    const [bMsg, setbMsg] = useState(false)
    const [sMsg, setsMsg] = useState(<></>)
    const [sMsgTitle, setsMsgTitle] = useState('')
    const [iMsgType, setiMsgType] = useState(1)
    const [bCloseIcon, setCloseIcon] = useState(true)

    //Loading setting
    const [loading, setLoading] = useState(false)

    //部落格文章
    const [vArticleData, setvArticleData] = useState<iArticle[]>([])

    //Banner State
    const [sBanner, setBanner] = useState<{ img: string; url: string }>({ img: '', url: '' })

    // set msg / modal
    // Loading : setAlert("Msg", true, <></>, "", 0);
    const setAlert = (
        type: string, // "Modal" or "Msg"
        bAlert: boolean, // show or not show
        sAlert: JSX.Element = <></>, // content
        sAlertTitle: string = '', // title
        iMsgType: number = 1, // "Msg" icon type, discard this param when type === "Modal"
        closeIcon: boolean = true,
        clickBg: boolean = false //可不可以透過暗灰色處關閉
    ) => {
        setClickBg(clickBg)
        if (type === 'Modal') {
            setbModal(bAlert)
            setsModal(sAlert)
            setsModalTitle(sAlertTitle)
            setCloseIcon(closeIcon)
        } else if (type === 'Msg') {
            setbMsg(bAlert)
            setsMsg(sAlert)
            setsMsgTitle(sAlertTitle)
            setiMsgType(iMsgType)
            setCloseIcon(closeIcon)
        }
    }

    // check login type
    // const loginFromGoogle = () => {
    //     switch (sTokenType.current) {
    //         case vTokenType[0]:
    //             return false;
    //         case vTokenType[1]:
    //             return true;
    //         default:
    //             return "not_auth";
    //     }
    // };

    // update user info
    const updateUserInfo = (native_token: string) => {
        getUserInfo(native_token)
            .then((res) => {
                if (res.success) {
                    const vNewUserInfo: UserInfo = {
                        name: res.user.name,
                        user_id: res.user.user_id,
                        user_code: res.user.user_code,
                        phone: res.user.phone,
                        share_code: res.user.share_code,
                        email: res.user.email,
                        points: res.user.points,
                        photo: res.user.photo,
                        bank_code: res.user.bank_code,
                        bank_branch: res.user.bank_branch,
                        bank_account_number: res.user.bank_account_number,
                        bank_account_name: res.user.bank_account_name,
                        birth: res.user.birth,
                        comment_count: res.user.comment_count,
                        response_count: res.user.response_count,
                        has_bank_info: res.user.has_bank_info,
                    }
                    bAuth.current = true
                    setvUserInfo(vNewUserInfo)
                }
            })
            .catch((error: any) => {
                resetUser()
                console.error(error)
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    // reset user info
    const resetUser = () => {
        bAuth.current = false
        sAccessToken.current = ''
        sIdToken.current = ''
        beLogin.current = false
        sRefreshToken.current = ''
        removeRefreshToken()
        setvUserInfo(vUserInfoDefault)
        setAlert('Modal', false)
    }

    // *** localStorage start *** //
    const saveRefreshToken = (refresh_token: string /*, token_iss: string*/) => {
        window.localStorage.setItem('refresh_token', refresh_token)
        // window.localStorage.setItem('token_iss', token_iss)
    }

    const getRefreshToken = () => {
        const refresh_token = window.localStorage.getItem('refresh_token')
        // const token_iss = window.localStorage.getItem('token_iss')
        // if (!token_iss || !vTokenType.includes(token_iss)) return false
        if (!refresh_token) return false

        // sTokenType.current = token_iss
        // if (loginFromGoogle()) {
        //     sRefreshToken.current = refresh_token
        // } else {
        sIdToken.current = refresh_token
        beLogin.current = true
        // }
        return true
    }

    const removeRefreshToken = () => {
        window.localStorage.removeItem('refresh_token')
        window.localStorage.removeItem('token_iss')
        window.localStorage.removeItem('beLogin')
    }
    // *** localStorage end *** //

    // *** oauth login start *** //
    // update oauth token
    // const updateOauthToken = (
    //     res: vUserOutput | vTokenOutput,
    //     access_token: string,
    //     id_token: string,
    //     refresh_token: string
    // ) => {
    //     // updateUserInfo(res);
    //     sAccessToken.current = access_token;
    //     sIdToken.current = id_token;
    //     sRefreshToken.current = refresh_token;
    //     sTokenType.current = res.token_iss;
    //     saveRefreshToken(refresh_token /*, res.token_iss*/);
    // };

    const loginOauth = (email: string, name: string, googleId: string) => {
        loginGoogleUser({ email: email, name: name, id: googleId })
            .then((res: any) => {
                if (res.success) {
                    if (res.need_register) {
                        //帳號還需註冊
                        navigate('/login/register', {
                            state: {
                                name: name,
                                email: email,
                                id: googleId,
                                from: 'google',
                            },
                        })
                        return
                    }
                    updateNativeToken(res.token)
                    if (res.validation === 0 || res.validation === 1) {
                        setAlert('Modal', false)
                        ChangeURL('/login/invite/phone')
                    } else {
                        if (location.pathname.indexOf('rankpage') > -1) {
                            setAlert('Modal', false)
                        } else {
                            ChangeURL('/') //go home
                        }
                    }
                } else {
                    VerifyAlert({
                        title: '登入失敗！',
                        content: `${res.msg}`,
                        btnText: '返回',
                        MsgType: 2,
                        btnOnclick: () => {
                            setAlert('Msg', false)
                        },
                        setAlert: setAlert,
                    })
                    console.error(res.msg)
                }
            })
            .catch((error: any) => {
                console.error(error)
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    const loginFaceBook = (email: string, name: string, id: string) => {
        loginFaceBookUser({ email: email, name: name, id: id })
            .then((res: any) => {
                if (res.success) {
                    if (res.need_register) {
                        //帳號還需註冊
                        navigate('/login/register', {
                            state: {
                                name: name,
                                email: email,
                                id: id,
                                from: 'facebook',
                            },
                        })
                        return
                    }
                    updateNativeToken(res.token)
                    if (res.validation === 0 || res.validation === 1) {
                        setAlert('Modal', false)
                        ChangeURL('/login/invite/phone')
                    } else {
                        if (location.pathname.indexOf('rankpage') > -1) {
                            setAlert('Modal', false)
                        } else {
                            ChangeURL('/') //go home
                        }
                    }
                } else {
                    VerifyAlert({
                        title: '登入失敗！',
                        content: `${res.msg}`,
                        btnText: '返回',
                        MsgType: 2,
                        btnOnclick: () => {
                            setAlert('Msg', false)
                        },
                        setAlert: setAlert,
                    })
                    console.error(res.msg)
                }
            })
            .catch((error: any) => {
                console.error(error)
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    // const refreshOauthToken = () => {
    // refreshUser(sRefreshToken.current)
    //     .then((res) => {
    //         updateOauthToken(res, res.access_token, res.id_token, sRefreshToken.current);
    //     })
    //     .catch((error: any) => {
    //         resetUser();
    //         console.error(error);
    //         let error_status: number;
    //         if (error.response !== undefined) {
    //             error_status = error.response.status;
    //         } else {
    //             error_status = 0;
    //         }
    //         errorProcess(error_status, error);
    //     });
    // };

    // const logoutOauth = () => {
    // logoutUser(sAccessToken.current)
    //     .then(() => {
    //         resetUser();
    //         setAlert("Msg", false);
    //     })
    //     .catch((error: any) => {
    //         resetUser();
    //         console.error(error);
    //         let error_status: number;
    //         if (error.response !== undefined) {
    //             error_status = error.response.status;
    //         } else {
    //             error_status = 0;
    //         }
    //         errorProcess(error_status, error);
    //     });
    // };

    // *** oauth login end *** //

    // *** native login start *** //
    // update native token
    const updateNativeToken = (native_token: string) => {
        updateUserInfo(native_token)
        sIdToken.current = native_token
        // sTokenType.current = res.token_iss;
        bAuth.current = true
        beLogin.current = true
        window.localStorage.setItem('beLogin', beLogin.current.toString())
        saveRefreshToken(native_token /*, res.token_iss*/)
    }

    const nativeLogin = (account: { email: string; password: string }) => {
        loginNativeUser(account)
            .then((res: any) => {
                if (res.success) {
                    updateNativeToken(res.token)

                    if (res.validation === 0 || res.validation === 1) {
                        setAlert('Modal', false)
                        ChangeURL('/login/invite/phone')
                    } else {
                        if (location.pathname.indexOf('rankpage') > -1) {
                            setAlert('Modal', false)
                        } else {
                            ChangeURL('/') //go home
                        }
                    }
                } else {
                    VerifyAlert({
                        title: '登入失敗！',
                        content: `${res.msg}`,
                        btnText: '返回',
                        MsgType: 2,
                        btnOnclick: () => {
                            setAlert('Msg', false)
                        },
                        setAlert: setAlert,
                    })
                    console.error(res.msg)
                }
            })
            .catch((error: any) => {
                console.error(error)
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    // const refreshNativeToken = () => {
    // refreshUserNative(sIdToken.current)
    //     .then((res) => {
    //         updateNativeToken(res, res.token);
    //     })
    //     .catch((error: any) => {
    //         resetUser();
    //         console.error(error);
    //         let error_status: number;
    //         if (error.response !== undefined) {
    //             error_status = error.response.status;
    //         } else {
    //             error_status = 0;
    //         }
    //         // errorProcess(error_status, error);
    //     });
    // };

    const logoutNative = () => {
        navigate('/')
        resetUser()
    }
    // *** native login end *** //

    const ChangeURL = (url: string) => {
        navigate(url)
    }

    // axios catch
    const errorProcess = (errorStatus: number, errorMes: any) => {
        switch (errorStatus) {
            case 403:
                VerifyAlert({
                    title: '失敗！',
                    content: '請重新登入',
                    btnText: '返回首頁',
                    MsgType: 2,
                    btnOnclick: () => {
                        ChangeURL('/')
                        setAlert('Msg', false)
                    },
                    setAlert: setAlert,
                })
                break
            default:
                VerifyAlert({
                    title: '失敗！',
                    content: '',
                    btnText: '返回首頁',
                    MsgType: 2,
                    btnOnclick: () => {
                        ChangeURL('/')
                        setAlert('Msg', false)
                    },
                    setAlert: setAlert,
                })
                break
        }
    }

    //確認token是否還可使用
    const checkTokenVerify = () => {
        if (sIdToken.current !== '') {
            verifyToken(sIdToken.current)
                .then((res) => {
                    updateUserInfo(sIdToken.current)
                })
                .catch((error: any) => {
                    console.error(error)
                    let error_status: number
                    ChangeURL('/')
                    if (error.response !== undefined) {
                        error_status = error.response.status
                    } else {
                        error_status = 0
                    }
                    if (error_status === 403) {
                        resetUser()
                    }
                    // errorProcess(error_status, error);
                })
        }
    }
    const getArtcleFromAPI = () => {
        getArticleList()
            .then((res: any) => {
                if (res.success) {
                    setvArticleData(res.articles)
                }
            })
            .catch((error: any) => {
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    const getBannerSrc = () => {
        getBanner()
            .then(
                (res: {
                    success: boolean
                    banners: {
                        image: string
                        mobile_image: string
                        text: string | null
                        link: string | null
                    }[]
                }) => {
                    const width = window.screen.width
                    if (res.success && res.banners[0].link !== null) {
                        if (width >= 960) {
                            setBanner({ img: res.banners[0].image, url: res.banners[0].link })
                        } else {
                            setBanner({ img: res.banners[0].mobile_image, url: res.banners[0].link })
                        }
                    }
                }
            )
            .catch((error: any) => {
                let error_status: number
                if (error.response !== undefined) {
                    error_status = error.response.status
                } else {
                    error_status = 0
                }
                errorProcess(error_status, error)
            })
    }

    useEffect(() => {
        document.body.style.overflow = bModal || bMsg ? 'hidden' : ''
        // document.body.style.position = bModal || bMsg ? 'sticky' : ''
    }, [bModal, bMsg])

    useEffect(() => {
        //refresh window get local storage token
        getRefreshToken()
        checkTokenVerify()
        //拿取所有文章
        getArtcleFromAPI()
        //拿取Banner
        getBannerSrc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const vBaseContext = {
        beLogin,
        vUserInfo,
        sIdToken,
        bAuth,
        bClickBg,
        bModal,
        bMsg,
        sModal,
        sMsg,
        sModalTitle,
        sMsgTitle,
        iMsgType,
        bCloseIcon: bCloseIcon,
        vArticleData: vArticleData,
        loading: loading,
        sBanner: sBanner,
        setLoading,
        setiMsgType,
        setbMsg,
        setbModal,
        setAlert,
        setsMsg,
        setsModal,
        setsMsgTitle,
        setsModalTitle,
        ChangeURL,
        updateNativeToken,
        loginOauth,
        loginFaceBook,
        nativeLogin,
        logoutNative,
        errorProcess,
        checkTokenVerify,
        updateUserInfo,
    }

    // const countdown = () => {
    //     setTimeout(() => setLoading(false), 1000);
    // };

    // useEffect(() => {
    //     setLoading(true);
    //     countdown();
    // }, [location.pathname]);
    return (
        <Provider value={vBaseContext}>
            <ScrollToTop />
            {loading && <Loading />}
            <Layout>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route key={index} path={route.path} element={route.component} />
                    })}
                </Routes>
            </Layout>
            <Modal />
            <Message />
        </Provider>
    )
}

export default App
